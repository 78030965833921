import React, { Component } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from "react-router-dom";
import {config} from '../config';
class PopularRestaurant extends Component {
    constructor(props){
        super(props);{
            this.state = {
                restaurant_data :[],
                merchant_token : null
            }
        }
    }
    componentDidMount(){
      const url_merchant_token =
    `${config.api_root}/security/session/merchants?Key=diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq&Secret=ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx&device_id=21212121121212wqwqw`;
  fetch(url_merchant_token, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
      "Secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
    }
  }).then(response => response.json())
        .then(merchant => {
          this.setState({
            merchant_token: merchant.object.access_token
          },() =>{
            const  url = `${config.api_base}/merchants/shop/search?distance_min=20&type=RESTAURANTS,MOBILE_RESTAURANTS&distance_max=100&page=0&size=40&search=REGIONAL&lt=39.6579328&ln=-105.0799494&serviceType=NO&opened_only=false`;
        const bearer = "Bearer" + this.state.merchant_token;
        fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
              .then((responseData) => {
                  console.log("search results", responseData);
                this.setState({
                  restaurant_data: responseData.data,
                });
              })
            .catch(error => this.setState({
                message: 'Something bad happened ' + error
            }));
          });
        })
        .catch(error => this.setState({
            message: 'Something bad happened ' + error
        }));


    }
    render() {

        const popular_restaurant = this.state.restaurant_data.slice(12,16).map((item,index) =>{
          const restaurant_link = `/restaurant/${item.merchant_id}`;
            return(
                <div className="col-lg-3 col-md-6" key = {index}>
                    <div className="res-list">
                    <Link
                        to={{
                            pathname: `/restaurant/${item.merchant_id}`,
                           menuinfo: item.business.menu, restaurantinfo: item// your data array of objects
                        }}
                        >
                        <img src={item.business.LOGO} alt="images not found" className="img-fluid" />
                        </Link>
                        <div className="res-details">
                        <Link
                        to={{
                            pathname: `/restaurant/${item.merchant_id}`,
                            restaurantinfo: item,menuinfo: item.business.menu,infodata : this.state.restaurant_location// your data array of objects
                        }}
                        >
                        <h5>{item.name}</h5>
                        </Link>
                        <div className="price">
                            <span className="delivery-time">{item.business.MERCHANT_ADD_FEAT_DELIVERY_TIME}</span>
                            <span>${item.business.MERCHANT_ADD_FEAT_MINIMUM_ORDER} min... </span>
                        </div>
                        <div className="rating">
                        <StarRatingComponent
                                    name="rate2"
                                    editing={false}
                                    starCount={5}
                                    value={item.business.rating}
                                />
                            <span>{item.business.rating} ratings</span>
                        </div>
                        </div>
                    </div>
                    </div>
            )
        })
        return (
            <div className="p-restaurants-area">
                <div className="container">
                <h4>Popular Restaurants</h4>
                <div className="row">
                    {popular_restaurant}
                </div>
                <h5 className="view-all-btn Pop-btn" ><a href="/all-restaurant" className="view-allres">View all Popular Restaurants</a></h5>
                </div>
      </div>

        );
    }
}

export default PopularRestaurant;
