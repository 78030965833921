import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div>
                <div className="footer-area">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3">
                        <div className="footer-logo">
                        <a href="https://www.diningtek.com/" target="_blank"><img src="/images/DT_logo_web.png" alt="images not found" className="img-fluid" /></a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        {
                        //   <div className="subscribe">
                        // <h4>Subscribe for New offers</h4>
                        // <p>Sign up for coupons, discounts, and save your favorites so you can order even faster! Enter your email below.</p>
                        // <form className="footer-subscribe">
                        //     <input type="email" name="email" placeholder="Enter Your Email ID" />
                        //     <input type="submit" value="subscribe" />
                        // </form>
                        // </div>
                      }
                        <div className="contact subscribe">
                      <h4>Contact Us</h4>
                      <p>4770 Baseline Rd, Suite 200<br/>Boulder, CO 80303
                            {
                               // <br /> (720) 236 – 9572
                             }
                            </p>
                      <a href="/contact-us">Click here to contact us</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        {
                        //   <div className="contact">
                        // <h4>Contact Us</h4>
                        // <p>4770 Baseline Rd, Suite 200<br/>Boulder, CO 80303
                        //     <br /> (720) 236 – 9572</p>
                        // <a href="mailto: sales@diningtek.com">sales@diningtek.com</a>
                        // </div>
                      }
                      <div className = "legal">
                      <ul>
                        <h6>LEGAL</h6>
                        <li><a href="/termandcondition" target="_blank">Terms & Conditions</a></li>
                        <li><a href="/donationterms" target="_blank">Donation Terms</a></li>
                      </ul>

                      </div>
                      <div className="social">
                      <ul>
                          <li><a href="https://mobile.twitter.com/diningtek" target="_blank"><i className="fab fa-twitter" /></a></li>
                          <li><a href="https://www.facebook.com/DiningTek/" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                          {
                          // <li><a href="/"><i className="fab fa-google-plus-g" /></a></li>
                          // <li><a href="/"><i className="fab fa-instagram" /></a></li>
                        }
                          <li><a href="https://www.linkedin.com/company/diningtek" target="_blank"><i className="fab fa-linkedin-in" /></a></li>
                      </ul>
                      </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="copyright-area">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="copyright">
                        <p> © 2021 <a href="https://www.diningtek.com/" target="_blank">Diningtek</a></p>
                        </div>
                    </div>
                    {
                    //   <div className="col-md-7">
                    //
                    //       <div className="social">
                    //     <ul>
                    //         <li><a href="/"><i className="fab fa-twitter" /></a></li>
                    //         <li><a href="/"><i className="fab fa-facebook-f" /></a></li>
                    //         <li><a href="/"><i className="fab fa-google-plus-g" /></a></li>
                    //         <li><a href="/"><i className="fab fa-instagram" /></a></li>
                    //         <li><a href="/"><i className="fab fa-linkedin-in" /></a></li>
                    //     </ul>
                    //     </div>
                    //
                    // </div>
                  }
                    </div>
                </div>
                </div>
      </div>
        );
    }
}

export default Footer;
