import React, { Component, useState } from "react";
import StarRatingComponent from "react-star-rating-component";
import { Link } from "react-router-dom";
//import {restaurantslistdata} from "../../public/index.html";
import { config } from "../config";
import DateTimePicker from "react-datetime-picker";
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import Modal from "react-bootstrap/Modal";
import { Button, Container, Row, Col } from "react-bootstrap";
//const [value, onChange] = useState(new Date());
class NewRestaurant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant_data: [],
      responseData: null,
      merchant_token: null,
      activePage: 0,
      hasMore: true,
      total_restaurant: 0,
      showmodaldelivery: false,
      datevalue: new Date()
    };
  }

  // handlePageChange(pageNumber) {
  //    console.log(`active page is ${pageNumber}`);
  //    this.setState({
  //      activePage: pageNumber
  //    },() =>{
  //      const  url = `${config.api_base}/merchants/shop/search?distance_min=0&distance_max=20&page=${this.state.activePage - 1}&size=10&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
  //      const bearer = "Bearer" + this.state.merchant_token;
  //      fetch(url, {
  //              method: 'GET',
  //              headers: {
  //                  'Authorization': bearer,
  //                  'Content-Type': 'application/json'
  //              }
  //          }).then((response) => response.json())
  //            .then((responseData) => {
  //                console.log("search results", responseData);
  //              this.setState({
  //                restaurant_data: responseData.data,
  //                responseData:responseData
  //              });
  //            })
  //          .catch(error => this.setState({
  //              message: 'Something bad happened ' + error
  //          }));
  //    });
  //  }

  // fetchMoreData = () => {
  //   if (this.state.restaurant_data.length >= this.state.total_restaurant) {
  //     this.setState({ hasMore: false });
  //   } else {
  //     const url = `${config.api_base}/merchants/shop/search?distance_min=0&distance_max=20&type=RESTAURANTS,MOBILE_RESTAURANTS&page=${this
  //       .state.activePage +
  //       1}&size=12&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
  //     const bearer = "Bearer" + this.state.merchant_token;
  //     fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Authorization: bearer,
  //         "Content-Type": "application/json"
  //       }
  //     })
  //       .then(response => response.json())
  //       .then(responseData => {
  //         console.log("search results", responseData);
  //         this.setState(
  //           {
  //             restaurant_data: this.state.restaurant_data.concat(
  //               responseData.data
  //             ),
  //             responseData: responseData,
  //             total_restaurant: responseData.total
  //           },
  //           () => {
  //             this.setState({
  //               activePage: this.state.activePage + 1
  //             });
  //           }
  //         );
  //       })
  //       .catch(error =>
  //         this.setState({
  //           message: "Something bad happened " + error
  //         })
  //       );
  //   }
  // };
  // componentDidMount() {
  //   const url_merchant_token = `${config.api_root}/security/session/merchants?Key=diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq&Secret=ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx&device_id=21212121121212wqwqw`;
  //   fetch(url_merchant_token, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Key: "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
  //       Secret:
  //         "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(merchant => {
  //       this.setState({
  //         merchant_token: merchant.object.access_token
  //       });
  //     })
  //     .then(() => {
  //       const url = `${config.api_base}/merchants/shop/search?distance_min=0&type=RESTAURANTS,MOBILE_RESTAURANTS&distance_max=20&page=0&size=12&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
  //       const bearer = "Bearer" + this.state.merchant_token;
  //       fetch(url, {
  //         method: "GET",
  //         headers: {
  //           Authorization: bearer,
  //           "Content-Type": "application/json"
  //         }
  //       })
  //         .then(response => response.json())
  //         .then(responseData => {
  //           console.log("search results", responseData);
  //           this.setState({
  //             restaurant_data: responseData.data,
  //             responseData: responseData,
  //             total_restaurant: responseData.total
  //           });
  //         })
  //         .catch(error =>
  //           this.setState({
  //             message: "Something bad happened " + error
  //           })
  //         );
  //     })
  //     .catch(error =>
  //       this.setState({
  //         message: "Something bad happened " + error
  //       })
  //     );
  // }

  deliverChooseHandle = () => {
    this.setState({
      showmodaldelivery: true
    });
  };

  handleDateChange = event => {
    //this.logs.unshift("change: " + event.target.value);
    //console.log(event);

    this.setState({
      datevalue: event
    });
  };
  handleCloseDate = event => {
    //this.logs.unshift("change: " + event.target.value);
    //console.log(event);

    this.setState({
      showmodaldelivery: false
    });
  };

  render() {
    console.log("new restaurant", window.restaurantslistdata);
    const loadingrestaurants = (
      <div className="row loading-row">
        {Array.apply(null, Array(12)).map(function(item, i) {
          return (
            <div className="col-lg-4 col-md-6" key={i}>
              <div className="loader-img">
                <img
                  src="/images/DiningTekLoader.gif"
                  alt="images not found"
                  className="img-fluid"
                />
              </div>
            </div>
          );
        }, this)}
      </div>
    );
    const loadingrestaurantsNew = (
      <div className="row loading-row">
        {Array.apply(null, Array(3)).map(function(item, i) {
          return (
            <div className="col-lg-4 col-md-6" key={i}>
              <div className="loader-img">
                <img
                  src="/images/DiningTekLoader.gif"
                  alt="images not found"
                  className="img-fluid"
                />
              </div>
            </div>
          );
        }, this)}
      </div>
    );

    // const new_restaurant = this.state.restaurant_data.slice(0,4).map((item,index) =>{
    const new_restaurant =
      window.restaurantslistdata != undefined
        ? window.restaurantslistdata.length > 0
          ? window.restaurantslistdata.map((item, index) => {
              const restaurant_link = `/restaurant/${
                item.merchant_id ? item.merchant_id : null
              }`;

              return (
                <div className="col-lg-12 col-xl-6" key={index}>
                  <div className="resto-order">
                    <div className="restaurent-box">
                      <div className="rest-badge best-seller">Popular</div>
                      {/*<div className="rest-badge recmend-seller">Recommended</div>
                      <div className="rest-badge best-seller">Best Seller</div> */}
                      <div className="retaurent-img">
                        {item.outletShop == true ? (
                          <a href={item.business.OUTLET_ENDPOINT}>
                            <img
                              src={item.business.LOGO}
                              alt="images not found"
                              className="img-fluid"
                            />
                          </a>
                        ) : item.business &&
                          item.business.ECOMMERCE_STATUS == "false" ? (
                          <img
                            src={item.business.LOGO}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <Link
                            to={{
                              pathname: `/restaurant/${item.merchant_id}`,
                              menuinfo: item.business.menu,
                              restaurantinfo: item // your data array of objects
                            }}
                          >
                            <img
                              src={item.business.LOGO}
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        )}
                      </div>
                      <div className="retaurent-txt">
                        <div className="rating-rest">
                          <i className="fas fa-star" />{" "}
                          {item.business.rating === 0
                            ? 5
                            : item.business.rating}
                        </div>
                        <div className="retaurent-name">
                          {item.outletShop == true ? (
                            <a href={item.business.OUTLET_ENDPOINT}>
                              <h3>
                                {item.name} - {item.city}
                              </h3>
                            </a>
                          ) : item.business &&
                            item.business.ECOMMERCE_STATUS == "false" ? (
                            <h3>
                              {item.name} - {item.city}
                            </h3>
                          ) : (
                            <Link
                              to={{
                                pathname: `/restaurant/${item.merchant_id}`,
                                restaurantinfo: item,
                                menuinfo: item.business.menu
                              }}
                            >
                              <h3>
                                {item.name} - {item.city}
                              </h3>
                            </Link>
                          )}
                          <p>{item.tagLine}</p>
                        </div>
                        <div className="time-close">
                          <span className="rest-time">
                            <img src="/images/time-icon.png" />{" "}
                            {item.outletShop == true ? (
                              item.business.IS_SHOP_OPEN === "true" ||
                              item.business.IS_SHOP_OPEN === "TRUE" ? (
                                <a href={item.business.OUTLET_ENDPOINT}>
                                  Place Order
                                </a>
                              ) : (
                                <a href={item.business.OUTLET_ENDPOINT}>
                                  View Menu
                                </a>
                              )
                            ) : item.business.ONLY_DONATION == "FALSE" ||
                              item.business.ONLY_DONATION === undefined ? (
                              item.business.DONATION === undefined &&
                              item.business.ECOMMERCE_STATUS == "true" ? (
                                item.business.IS_SHOP_OPEN === "true" ||
                                item.business.IS_SHOP_OPEN === "TRUE" ? (
                                  <Link
                                    to={{
                                      pathname: `/restaurant/${item.merchant_id}`,
                                      restaurantinfo: item,
                                      menuinfo: item.business.menu // your data array of objects
                                    }}
                                  >
                                    Place Order
                                  </Link>
                                ) : (
                                  <Link
                                    to={{
                                      pathname: `/restaurant/${item.merchant_id}`,
                                      restaurantinfo: item,
                                      menuinfo: item.business.menu // your data array of objects
                                    }}
                                  >
                                    View Menu
                                  </Link>
                                )
                              ) : item.business.DONATION === undefined &&
                                item.business.ECOMMERCE_STATUS == "false" ? (
                                <a
                                  data-tip
                                  data-for={item.name}
                                  data-place="top"
                                  data-type="dark"
                                  data-effect="solid"
                                >
                                  Place Order
                                </a>
                              ) : item.business.DONATION == "FALSE" &&
                                item.business.ECOMMERCE_STATUS == "true" ? (
                                item.business.IS_SHOP_OPEN === "true" ||
                                item.business.IS_SHOP_OPEN === "TRUE" ? (
                                  <Link
                                    to={{
                                      pathname: `/restaurant/${item.merchant_id}`,
                                      restaurantinfo: item,
                                      menuinfo: item.business.menu // your data array of objects
                                    }}
                                  >
                                    Place Order
                                  </Link>
                                ) : (
                                  <Link
                                    to={{
                                      pathname: `/restaurant/${item.merchant_id}`,
                                      restaurantinfo: item,
                                      menuinfo: item.business.menu // your data array of objects
                                    }}
                                  >
                                    View Menu
                                  </Link>
                                )
                              ) : null
                            ) : null}
                          </span>
                          <span className="rest-close">
                            <img src="/images/rclose.png" />{" "}
                            {item.business.IS_SHOP_OPEN === "true" ||
                            item.business.IS_SHOP_OPEN === "TRUE"
                              ? "OPEN"
                              : "CLOSED"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn-order-ahead"
                      onClick={this.deliverChooseHandle}
                    >
                      Order Ahead
                    </button>
                  </div>
                </div>
              );
            })
          : null
        : null;
    return (
      <section id="near-retaurent">
        <div className="container">
          {this.state.showmodaldelivery ? (
            <div id="loadingOverlay" className="loader-overlay">
      <div className="loader-content loader-center">
      <h2>Order Ahead</h2>
        <div className="loader-center loader-text">  <DateTimePicker
            onChange={this.handleDateChange}
            value={this.state.datevalue}
          /></div>
        <button
        className="btnorder"
        onClick={this.handleCloseDate}
        >Order Ahead</button>
      </div>
    </div>

          ) : null}
          <h2>Restaurant Near You</h2>

          {window.restaurantslistdata && window.restaurantslistdata != undefined
            ? window.restaurantslistdata.length > 0
              ? null
              : loadingrestaurants
            : null}
          <InfiniteScroll
            dataLength={
              window.restaurantslistdata ? window.restaurantslistdata.length : 0
            }
            //next={() => this.fetchMoreData()}
            //hasMore={this.state.hasMore}
            loader={loadingrestaurantsNew}
          >
            <div className="row">{new_restaurant}</div>
          </InfiniteScroll>
          <Modal show={this.state.showmodaldggelivery} id="modal3" size="sm">
            <Modal.Body>
              <DateTimePicker
                onChange={this.handleDateChange}
                value={this.state.datevalue}
              />
            </Modal.Body>
            }
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleclosedelivery}>
                ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </section>
    );
  }
}

export default NewRestaurant;
