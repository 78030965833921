import React, { Component } from 'react';
import HeaderTwo from "./HeaderTwo";
import Footer from "./Footer";
class ThankYou extends Component {
  constructor(props){
    super(props);
  }
    render() {
      console.log("order_info",this.props.location.order_info)
        return (
            <>
                <HeaderTwo />
                <div className = "row">
                    <div className ="container">
                        <div className ="Booking-confirmation">
                        <h2>Thank You!</h2>
                        <h2>Order Successfull!</h2>
                        </div>
                        <div className="row">
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                          {this.props.location &&
                            this.props.location.order_info &&
                            this.props.location.order_info.object &&
                            this.props.location.order_info.object.order_detail ?
                             (
                               <div className="order-detail">
                                 <h2>Order Detail</h2>
                                 <div className="corn">

                                 {this.props.location.order_info.object.order_detail.items ?
                                   this.props.location.order_info.object.order_detail.items.map((item,index) =>(
                                     <div className="corn-contant">
                                     <p>{item.itemName}</p>
                                     {item.addons && item.addons.length > 0 ? item.addons.map((addon,index) =>(
                                       <p className="cart-addon">
                                         {addon.addon_full_name}
                                       </p>
                                     )):null}
                                         <div className="pamout show_button" id="pamut-number">
                                           <span>${item.unit_price}</span>
                                           <div className="count button_box" id="countted">
                                             <div className=" handle-counter" id="handleCounter14">
                                              {item.qty}
                                            </div>
                                          </div>
                                         </div>
                                     </div>
                                   )):null
                                  }
                                 </div>
                                 <div className="row cart-below">
                                   <div className="col-md-6">
                                   {this.props.location.order_info.object.order_detail.itemsFees &&
                                     this.props.location.order_info.object.order_detail.itemsFees.length > 0 ?
                                     this.props.location.order_info.object.order_detail.itemsFees.map((item_fees,index) =>(
                                       <h6>{item_fees.name}</h6>
                                     )):null
                                    }
                                    {this.props.location.order_info.object.order_detail.taxes &&
                                      this.props.location.order_info.object.order_detail.taxes.length > 0 ?
                                      this.props.location.order_info.object.order_detail.taxes.map((tax,index) =>(
                                        <h6>{tax.name}</h6>
                                      )):null
                                     }
                                     <h6>Order Total</h6>
                                   </div>
                                   <div className="col-md-6">
                                   {this.props.location.order_info.object.order_detail.itemsFees &&
                                     this.props.location.order_info.object.order_detail.itemsFees.length > 0 ?
                                     this.props.location.order_info.object.order_detail.itemsFees.map((item_fees,index) =>(
                                       <h6>{item_fees.amount}</h6>
                                     )):null
                                    }
                                    {this.props.location.order_info.object.order_detail.taxes &&
                                      this.props.location.order_info.object.order_detail.taxes.length > 0 ?
                                      this.props.location.order_info.object.order_detail.taxes.map((tax,index) =>(
                                        <h6>{tax.amount}</h6>
                                      )):null
                                     }
                                     <h6>{this.props.location.order_info.object.order_detail.orderTotal}</h6>
                                   </div>
                                 </div>
                                 </div>
                             ) : null
                          }

                          </div>
                          <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default ThankYou;
