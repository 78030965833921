import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button ,
  Container,
    Row,
    Col,
  Form,
  } from 'react-bootstrap';
import {config} from '../config';
class HeaderTwo extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
}

handleShowlogout = () =>{
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
  // localStorage.removeItem('access_token').then(() => {
  //   return <Redirect to="/" />
  // })
  if (localStorage.getItem('user') === null && localStorage.getItem('access_token') === null){
    window.location.reload();

  }

}

    render() {
      console.log("register_info------------------------------------",this.state.register_info);
      let local_user = localStorage.getItem('user');
      let local_token = localStorage.getItem('access_token');
      const restId = this.props && this.props.resid ? this.props.resid : null;
      console.log("restId",restId);
      const menu_content = local_user === null && local_token === null ?
        (<ul>
          <li><a href="/">Search</a></li>
          {/*<li><a href="/">Offers</a></li>
          <li><a href={'/cart/'+ restId}>Cart</a></li> */}
        <li><a href = "/signin"><button  className="headertwo_login">Login</button></a></li>
        <li><a href = "/signup"><button  className="headertwo_signup">Sign up</button></a></li>
        </ul>
      ) : (
        <ul>
          <li><a href="/">Search</a></li>
          <li><a href="/">Offers</a></li>
          <li><a href="/">Cart</a></li>
        <li><button  className="headertwo_login" onClick = {this.handleShowlogout}>Logout</button></li>
        <li></li>
        </ul>
        );

        return (
          <>
            <header className="header-area2 fixed-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar2 navbar-expand-lg navbar-light">
              <a className="navbar-brand navbar-brand2" href="/">
                  <img src="/images/DT_logo_web.png" alt="logo" />
                </a>
                <button className="hamburger hamburger--squeeze navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                  </ul>
                  <div className="main-menu-part2">
                  {menu_content}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
</>
        );
    }
}

export default HeaderTwo;
