import React,{Component} from 'react';
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';
import StripeScriptLoader from "react-stripe-script-loader";
import App from './App';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Restaurant from './components/Restaurant';
import Restaurant2 from './components/Restaurant2';
import RestaurantInfo from './components/RestaurantInfo';
import RestaurantInfo2 from './components/RestaurantInfo2';
import RestaurantData from './components/RestaurantData';
import RestaurantData2 from './components/RestaurantData2';
import Checkout from './components/Checkout';
import Contactus from './components/Contactus';
import Cart from './components/Cart';
import Signup from './components/Signup';
import Signin from './components/Signin';
import ForgetPassword from './components/ForgetPassword';
import ChangePassword from './components/ChangePassword';
import Donation from './components/Donation';
import DonationThankYou from './components/DonationThankYou';
import DonationTerms from './components/DonationTerms';


import TermAndCondition from './components/TermAndCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import ThankYou from './components/ThankYou';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import {config} from './config';

class Root extends Component {
    constructor(props){
        super(props);{
            this.state = {
                user_root_email : "",
                user_root_access_token : "",
                stripe_info : null,
                stripe_publish_key : null,
                stripe_account_id : null
            }
        }
    }
    callbackFunctionRoot = (value1,value2) => {
        this.setState({
            user_root_email: value1,
            user_root_access_token : value2
        })
    }

    callbackFunction = (stripe_info) => {
      this.setState({
        stripe_info: stripe_info
      },() =>{
        this.setState({
          stripe_publish_key : this.state.stripe_info.STRIPE_PUBLISHABLE_KEY
        })
        console.log("neha###################################################################################################################################################################");
        if(this.state.stripe_info.STRIPE_ACCOUNT_ID){
          this.setState({
            stripe_account_id :this.state.stripe_info.STRIPE_ACCOUNT_ID
          })
        }
      })

}


    render() {
        console.log("email_root",this.state.user_root_email);
        console.log("access_root",this.state.user_root_access_token);
        console.log("stripe_info----vn",this.state.stripe_info !== undefined && this.state.stripe_info !== null ? Object.keys(this.state.stripe_info).length : 'neha');
        return (
        <Router>
            <Switch>
                <Route exact path="/"  render={() => <App parentCallbackRoot={this.callbackFunctionRoot} />}/>
                 <Route path="/restaurants" component={Restaurant}/>
                 <Route path="/all-restaurant" component={Restaurant2}/>
                 <Route path="/restaurantinfo" component={RestaurantInfo} />
                 <Route path="/restaurantinfo2" component={RestaurantInfo2} />
                 <Route path="/restaurant/:id"  render={(props) => <RestaurantData2 {...props} parentCallback={this.callbackFunction} />}/>
                <Route path="/restaurant" component={RestaurantData}  />
                <Route path="/cart/:id" component={Cart}  />
                 <Route path="/thankyou" component={ThankYou} />
                 <Route path="/contact-us" component={Contactus} />
                 <Route path="/termandcondition" component={TermAndCondition} />
                 <Route path="/privacypolicy" component={PrivacyPolicy} />
                 <Route path="/signup" component={Signup} />
                 <Route path="/signin" component={Signin} />
                 <Route path="/forgetpassword" component={ForgetPassword} />
                 <Route path="/changepassword" component={ChangePassword} />
                 <Route path="/thank-you" component={DonationThankYou} />
                 <Route path="/donationterms" component={DonationTerms} />
                 <Route path="/donation/:id" render={(props) => <Donation {...props} parentCallback={this.callbackFunction} />} />
                 
                 <StripeScriptLoader
                   uniqueId="myUniqueId"
                   script="https://js.stripe.com/v3/"
                   loader="Loading..."
                 >

                 <StripeProvider  apiKey={this.state.stripe_publish_key != null  ? this.state.stripe_publish_key : 'pk_test_sn4v71GtpdSuGyF3oVJLSj7I'} stripeAccount={this.state.stripe_account_id != null &&  this.state.stripe_account_id ?  this.state.stripe_account_id : undefined}>
                 <Elements>
                 <>
                  <Route path="/checkout/:id" render={(props) => <Checkout {...props} parentCallback={this.callbackFunction} />} />
                  </>
                  </Elements>
                  </StripeProvider>
                  </StripeScriptLoader>

            </Switch>
        </Router>
        );
    }
}

export default Root;
