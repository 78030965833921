import React, { Component } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from "react-router-dom";
import HeaderTwo from './HeaderTwo';
import Search from './Search';
import AboveHeader from './AboveHeader';
import Pagination from "react-js-pagination";
import {config} from '../config';
import ReactTooltip from 'react-tooltip';
import { Range, getTrackBackground } from 'react-range';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { connect } from "react-redux";
// import { createContact } from "../share_data/actions";

import Footer from './Footer';
import { Redirect } from "react-router-dom";
import "bootstrap-less";

const STEP = 0.1;
const MIN = 0;
const MAX = 100;
class Restaurant2 extends Component {
    constructor(props){
        super(props);
        this.handleDistanceChange = this.handleDistanceChange.bind(this)
        this.state = {
            restaurants: [],
            restaurant_data:[],
            distance_values: [0, 20],
            rating_five_star : "",
            rating_four_star : "",
            rating_three_star : "",
            rating_two_star : "",
            rating_one_star : "",
            category_restaurant : "",
            category_mobile_restaurant : "",
            category_reservation : "",
            food_type_veg: "",
            food_type_non_veg: "",
            online_payment : "",
            cash_on_delivery : "",
            coupon_accept : "",
            available_type_open : "",
            options_delivery : "",
            options_take_away : "",
            rating_five_star_state : false,
            rating_four_star_state : false,
            rating_three_star_state : false,
            rating_two_star_state : false,
            rating_one_star_state : false,
            food_type_veg_state : false,
            food_type_non_veg_state : false,
            available_type_open_state : false,
            options_delivery_state : false,
            options_take_away_state : false,
            responseData:[],
            filter_state:false,
            activePage: 0,
            message:'',
            merchant_token:null,
            hasMore:true,
            total_restaurant:0,
            restaurant_location:this.props.location && this.props.location.location_data ? this.props.location.location_data : []
        }
    }
    handlePageChange(pageNumber) {
       console.log(`active page is ${pageNumber}`);
       this.setState({
         activePage: pageNumber
       },() =>{
         const  url = `${config.api_base}/merchants/shop/search?distance_min=${this.state.distance_values[0].toFixed(1)}&type=RESTAURANTS,MOBILE_RESTAURANTS&distance_max=${this.state.distance_values[1].toFixed(1)}&page=${this.state.activePage - 1}&size=10&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
         const bearer = "Bearer" + this.state.merchant_token;
         fetch(url, {
                 method: 'GET',
                 headers: {
                     'Authorization': bearer,
                     'Content-Type': 'application/json'
                 }
             }).then((response) => response.json())
               .then((responseData) => {
                   console.log("search results", responseData);
                 this.setState({
                   restaurant_data: responseData.data,
                   responseData:responseData,
                   total_restaurant:responseData.total
                 });
               })
             .catch(error => this.setState({
                 message: 'Something bad happened ' + error
             }));
       });
     }

     fetchMoreData = () => {
       if(this.state.restaurant_data.length >= this.state.total_restaurant){
         this.setState({hasMore: false})
       }
       else{
         const  url = `${config.api_base}/merchants/shop/search?distance_min=${this.state.distance_values[0].toFixed(1)}&type=RESTAURANTS,MOBILE_RESTAURANTS&distance_max=${this.state.distance_values[1].toFixed(1)}&page=${this.state.activePage + 1}&size=12&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
         const bearer = "Bearer" + this.state.merchant_token;
         fetch(url, {
                 method: 'GET',
                 headers: {
                     'Authorization': bearer,
                     'Content-Type': 'application/json'
                 }
             }).then((response) => response.json())
               .then((responseData) => {
                   console.log("search results", responseData);
                 this.setState({
                   restaurant_data: this.state.restaurant_data.concat(responseData.data),
                   responseData:responseData,
                   total_restaurant:responseData.total
                 },() =>{
                   this.setState({
                     activePage:this.state.activePage + 1
                   })
                 });
               })
             .catch(error => this.setState({
                 message: 'Something bad happened ' + error
             }));
       }

  };
    componentDidMount(){

      const url_merchant_token =
    `${config.api_root}/security/session/merchants?Key=diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq&Secret=ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx&device_id=21212121121212wqwqw`;
  fetch(url_merchant_token, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
      "Secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
    }
  }).then(response => response.json())
        .then(merchant => {
          this.setState({
            merchant_token: merchant.object.access_token
          });
        }).then(() =>{
          //const  url = `${config.api_base}/merchants/shop/search?distance_min=0.0&distance_max=100&page=0&size=50&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
          const  url = `${config.api_base}/merchants/shop/search?distance_min=0&distance_max=20&type=RESTAURANTS,MOBILE_RESTAURANTS&page=0&size=12&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
          const bearer = "Bearer" + this.state.merchant_token;
          fetch(url, {
                  method: 'GET',
                  headers: {
                      'Authorization': bearer,
                      'Content-Type': 'application/json'
                  }
              }).then((response) => response.json())
                .then((responseData) => {
                    console.log("search results", responseData);
                  this.setState({
                    restaurant_data: responseData.data,
                    responseData:responseData,
                    total_restaurant:responseData.total
                  });
                })
              .catch(error => this.setState({
                  message: 'Something bad happened ' + error
              }));
        }).catch(error => this.setState({
            message: 'Something bad happened ' + error
        }));




    }

    handleDistanceChange(values){
      this.setState({
        distance_values : values
      });
      console.log("this.state.values[0].toFixed(1)",this.state.values[0].toFixed(1));
      console.log("this.state.values[1].toFixed(1)",this.state.values[1].toFixed(1));

      const  url = `${config.api_base}/merchants/shop/search?distance_min=${this.state.values[0].toFixed(1)}&distance_max=${this.state.values[1].toFixed(1)}&type=RESTAURANTS,MOBILE_RESTAURANTS&page=0&size=20&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
      const bearer = "Bearer" + this.state.merchant_token;
      fetch(url, {
              method: 'GET',
              headers: {
                  'Authorization': bearer,
                  'Content-Type': 'application/json'
              }
          }).then((response) => response.json())
            .then((responseData) => {
                console.log("search results", responseData);
              this.setState({
                restaurant_data: responseData.data,
                responseData:responseData,
                total_restaurant:responseData.total
              });
            })
          .catch(error => this.setState({
              message: 'Something bad happened ' + error
          }));
    }

    handlefilterchange = event =>{
      event.preventDefault();
      let available_type_open = "";
      let rating_five_star = "";
      let rating_four_star = "";
      let rating_three_star = "";
      let rating_two_star = "";
      let rating_one_star = "";
      let food_type_veg = "";
      let food_type_non_veg = "";
      let options_delivery = "";
      let options_take_away = "";
      if(this.state.rating_five_star_state === true){
         rating_five_star = event.target[1].value;
      }
      if(this.state.rating_four_star_state === true){
         rating_four_star = event.target[2].value;
      }
      if(this.state.rating_three_star_state === true){
         rating_three_star = event.target[3].value;
      }
      if(this.state.rating_two_star_state === true){
         rating_two_star = event.target[4].value;
      }
      if(this.state.rating_one_star_state === true){
         rating_one_star = event.target[5].value;
      }
      if(this.state.food_type_veg_state === true){
         food_type_veg = event.target[6].value;
      }
      if(this.state.food_type_non_veg_state === true){
         food_type_non_veg = event.target[7].value;
      }
      if(this.state.available_type_open_state === true){
         available_type_open = event.target[8].value;
      }
      if(this.state.options_delivery_state === true){
         options_delivery = event.target[9].value;
      }
      if(this.state.options_take_away_state === true){
         options_take_away = event.target[10].value;
      }
      // const category_restaurant = document.getElementById('category_restaurant').value;
      // const category_mobile_restaurant = document.getElementById('category_mobile_restaurant').value;
      // const category_reservation = document.getElementById('category_reservation').value;
      // const online_payment = document.getElementById('online_payment').value;
      // const cash_on_delivery = document.getElementById('cash_on_delivery').value;
      // const coupon_accept = document.getElementById('coupon_accept').value;


      this.setState({
        rating_five_star : rating_five_star,
        rating_four_star : rating_four_star,
        rating_three_star : rating_three_star,
        rating_two_star : rating_two_star,
        rating_one_star : rating_one_star,
        // category_restaurant : category_restaurant,
        // category_mobile_restaurant : category_mobile_restaurant,
        // category_reservation : category_reservation,
        food_type_veg: food_type_veg,
        food_type_non_veg: food_type_non_veg,
        // online_payment : online_payment,
        // cash_on_delivery : cash_on_delivery,
        // coupon_accept : coupon_accept,
        available_type_open : available_type_open,
        options_delivery : options_delivery,
        options_take_away : options_take_away,
      },() =>{
        console.log("rating_five_star",this.state);
        const merchant_add_feat_veg = this.state.food_type_veg !="" ? this.state.food_type_veg : "false";
        const merchant_add_feat_non_veg = this.state.food_type_non_veg !="" ? this.state.food_type_non_veg : "false";
        const open_only = this.state.available_type_open !="" ? this.state.available_type_open : "false";
        const merchant_add_feat_delivery = this.state.options_delivery !="" ? this.state.options_delivery : "false";
        const merchant_add_pickup_delivery = this.state.options_take_away !="" ? this.state.options_take_away : "false";
        const  filter_url = `${config.api_base}/merchants/shop/search?distance_min=${this.state.distance_values[0].toFixed(1)}&distance_max=${this.state.distance_values[1].toFixed(1)}&type=RESTAURANTS,MOBILE_RESTAURANTS&page=0&size=20&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false&ART-RATING=4-5&MERCHANT-ADD-FEAT-VEG=${merchant_add_feat_veg}&MERCHANT-ADD-FEAT-NOVEG=${merchant_add_feat_non_veg}&MERCHANT-ADD-FEAT-DELIVERY=${merchant_add_feat_delivery}&MERCHANT-ADD-FEAT-PICKUP=${merchant_add_pickup_delivery}`;
        console.log("url",filter_url);
        const bearer = "Bearer" + this.state.merchant_token;
        fetch(filter_url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
              .then((responseData) => {
                  console.log("search results", responseData);
                this.setState({
                  restaurant_data: responseData.data,
                  responseData:responseData,
                  total_restaurant:responseData.total
                });
              })
            .catch(error => this.setState({
                message: 'Something bad happened ' + error
            }));
       })


    }

    render() {

      // if (
      //   this.state.restaurant_location.length  == 0
      // ) {
      //   return <Redirect to="/" />;
      // }

      console.log("restaurant_email", this.props.email);
      console.log("restaurant_access_token", this.props.access_token);
        console.log("api data",this.state.restaurant_data);
        console.log("available_type_open_state",this.state.available_type_open_state);
        console.log("responseData",this.state.responseData);
        console.log("this.state.distance_values[0]",this.state.distance_values);

        //console.log("json data",this.renderrestaurant_data);
        console.log("restaurant_locationnew", this.state);

        const loadingrestaurantsNew = (
            <div className="row loading-row">
                {Array.apply(null, Array(3)).map(function(item, i){
                    return (
                      <div className="col-lg-4 col-md-6" key={i}>
                      <div className="loader-img">
                      <img src="/images/DiningTekLoader.gif" alt="images not found" className="img-fluid" />
                      </div>
                      </div>
                    );
                }, this)}
            </div>
        );

        const itemlist = this.state.restaurant_data != undefined ? this.state.restaurant_data.length > 0 ? this.state.restaurant_data.map((item,index) =>{
          console.log("menuinfo" , item.business.url_point);
          const restaurant_link = `/restaurant`;
            return(

              <div className="col-lg-4 col-md-6" key={index}>
        <div className="restaurant-item">
        {item.outletShop == true ?(
            <a href={item.business.OUTLET_ENDPOINT}><img src={item.business.LOGO} alt="images not found" className="img-fluid" /></a>
          ):item.business && item.business.ECOMMERCE_STATUS == "false" ? (
            <img src={item.business.LOGO} alt="images not found" className="img-fluid" />
          ):(<Link
                  to={{
                      pathname: `/restaurant/${item.merchant_id}`,
                     menuinfo: item.business.menu, restaurantinfo: item// your data array of objects
                  }}
                  ><img src={item.business.LOGO} alt="images not found" className="img-fluid" />
              </Link>)}
          <div className="restaurant-bdr">
            <div className="restaurant-content">
            {item.outletShop == true ?(
                <a href={item.business.OUTLET_ENDPOINT}><h4>{item.name} - {item.city}</h4></a>
              ):item.business && item.business.ECOMMERCE_STATUS == "false" ? (
                <h4>{item.name} - {item.city}</h4>
              ):(<Link
                    to={{
                        pathname: `/restaurant/${item.merchant_id}`,
                        restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                    }}
                    >
                    <h4>{item.name} - {item.city}</h4>
                    </Link>)}
              <div className="dt-type">{item.tagLine}</div>
              <div className="dt-infobox">
              {item.outletShop == true ?(
                <div className="dt-info">
                    <div className="dt-rating dt-ratingcolor">
                      <i style={{fontStyle: 'normal'}}>★</i>&nbsp;<span>{item.business.rating === 0 ? 5 : item.business.rating }</span>
                    </div>
                    <div>•</div>
                    <div>
                    {item.business.MERCHANT_ADD_FEAT_PICKUP_TIME}
                    </div>
                    <div>•</div>
                    <div className="nVWSi">
                    {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? 'OPEN' : 'CLOSED'}
                    </div>
                </div>
              ):item.business && item.business.ECOMMERCE_STATUS == "true" ? (
                <div className="dt-info">
                    <div className="dt-rating dt-ratingcolor">
                      <i style={{fontStyle: 'normal'}}>★</i>&nbsp;<span>{item.business.rating === 0 ? 5 : item.business.rating }</span>
                    </div>
                    <div>•</div>
                    <div>
                    {item.business.MERCHANT_ADD_FEAT_PICKUP_TIME}
                    </div>
                    <div>•</div>
                    <div className="nVWSi">
                    {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? 'OPEN' : 'CLOSED'}
                    </div>
                </div>
              ):null}
              </div>
              <div className="dt-infobox2"><span className="dt-coupon">10% off on all orders</span></div>
              <div className="dt-boxessurround">
              {item.outletShop == true ? (
                <>
                <div className="_3B2qG">
                <div className="_2ECk4 _24tlh">
                {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? (
                  <a href={item.business.OUTLET_ENDPOINT}>Place Order</a>
                ):(
                  <a href={item.business.OUTLET_ENDPOINT}>Place Order</a>
                )}

                      </div></div>
                  {item.business && item.business.DONATION == "FALSE" ? null : (
                    <div className="dt-donateinbox">
                        <div className="_2ECk4 _24tlh">
                          <a href={`/donation/${item.merchant_id}`}>Donate</a>
                        </div>
                    </div>
                  )}
                </>
              ):item.business.ONLY_DONATION == "FALSE" || item.business.ONLY_DONATION === undefined ?
              item.business.DONATION === undefined &&  item.business.ECOMMERCE_STATUS == "true" ? (
              <>
              <div className="_3B2qG">
              <div className="_2ECk4 _24tlh">
              {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? (
                <Link
                      to={{
                          pathname: `/restaurant/${item.merchant_id}`,
                          restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                      }}
                      >
                    Place Order
                      </Link>
              ):(
                <Link
                      to={{
                          pathname: `/restaurant/${item.merchant_id}`,
                          restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                      }}
                      >
                    Place Order
                      </Link>
              )}

                    </div></div>
                <div className="dt-donateinbox">
                    <div className="_2ECk4 _24tlh">
                      <a href={`/donation/${item.merchant_id}`}>Donate</a>
                    </div>
                </div>
              </>
            ):item.business.DONATION === undefined &&  item.business.ECOMMERCE_STATUS == "false" ?
            (
              <>
              <div className="_3B2qG">
              <div className="_2ECk4 _24tlh">
              <>
              <a
                data-tip
                data-for={item.name}
                data-place="top"
                data-type="dark"
                data-effect="solid"
              >
                Place Order
              </a>
              <ReactTooltip id={item.name}  >
                  <span class="maintenance-content">Currently only accepting donations through DiningTek services.</span>
              </ReactTooltip>
              </>
              </div></div>
                <div className="dt-donateinbox">
                    <div className="_2ECk4 _24tlh">
                      <a href={`/donation/${item.merchant_id}`}>Donate</a>
                    </div>
                </div>
              </>
            ) :item.business.DONATION == "FALSE" &&  item.business.ECOMMERCE_STATUS == "true" ?(
              <>
              <div className="_3B2qG place-order-btn">
              <div className="_2ECk4 _24tlh">
              {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? (
                <Link
                    to={{
                        pathname: `/restaurant/${item.merchant_id}`,
                        restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                    }}
                    >
                  Place Order
                    </Link>
              ):(
                <Link
                    to={{
                        pathname: `/restaurant/${item.merchant_id}`,
                        restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                    }}
                    >
                  View Menu
                    </Link>
              )}

                    </div></div>

              </>
            ):item.business.DONATION == "FALSE" &&  item.business.ECOMMERCE_STATUS == "false" ?(
              <></>
            ):null:(
              <>
                <div className="dt-donateinbox donation-btn">
                    <div className="_2ECk4 _24tlh">
                      <a href={`/donation/${item.merchant_id}`}>Donate</a>
                    </div>
                </div>
              </>
            )}
                </div>
            </div>
          </div>
        </div>
      </div>


            )
        }):null:null
        return (
            <>
            <AboveHeader info = {this.state.restaurant_location}/>
            <HeaderTwo />
            <Search />
            <div className = "container">
        {/* Restaurants-area start */}
        <div className = "row">
        <div className = "col-md-2">
        <div className="filter">
          <h6 className="form-titles">Filter</h6>
          {/*<button className="btn" value={this.state.filter_state} onClick={() =>this.setState({filter_state : !this.state.filter_state})}>+</button> */ }
        </div>
        <form className={this.state.filter_state === false ? 'filter_show rating-form' : 'filter_hide rating-form'} onSubmit={this.handlefilterchange}>
       <h6 className="form-titles">Distance</h6>
       <div className="form-group">
         {
           // <input type="range" className="form-control-range" id="formControlRange" />
         }
         <div className="Distance-area">
         <Range
          values={this.state.distance_values}
          step={STEP}
          min={MIN}
          max={MAX}
          //onChange={values => this.handleDistanceChange(values)}
          onChange={values => this.setState({distance_values: values })}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "15px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: this.state.distance_values,
                    colors: ["#ccc","#ff3c00", "#ccc"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "35px",
                width: "10px",
                borderRadius: "4px",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA"
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "#ff3c00" : "#CCC"
                }}
              />
            </div>
          )}
        />
        </div>
        <output style={{ textAlign: "center" }} id="output">
          {this.state.distance_values[0].toFixed(1)} miles - {this.state.distance_values[1].toFixed(1)} miles
        </output>
       </div>

       <div className="first-check-box">
         <h6 className="form-titles">Available Type</h6>
         <div className="form-check">
           <input className="form-check-input"
           type="checkbox"
           id="available_type_open"
           name="available_type_open"
           value="true"
           checked={this.state.available_type_open_state}
           // value={this.state.available_type_open}
           // onClick = {e => this.handleFieldChange('available_type_open', e)}
           onClick={() => this.setState({available_type_open_state: !this.state.available_type_open_state })}
           />
           <label className="form-check-label" htmlFor="available_type_open">
             Open
           </label>
         </div>
       </div>
       <div className="first-check-box">
         <h6 className="form-titles">Options</h6>
         <div className="form-check">
           <input className="form-check-input"
           type="checkbox"
           id="options_take_away"
           name="options_take_away"
           value="true"
           // value={this.state.options_take_away}
           // onClick = {e => this.handleFieldChange('options_take_away', e)}
           checked = {this.state.options_take_away_state}
           onClick={() => this.setState({options_take_away_state: !this.state.options_take_away_state })}
           />
           <label className="form-check-label" htmlFor="options_take_away">
             Pickup at Restaurant
           </label>
         </div>
         <div className="form-check">
           <input className="form-check-input"
           type="checkbox"
           id="options_delivery"
           name="options_delivery"
           value="true"
           // value={this.state.options_delivery}
           // onClick = {e => this.handleFieldChange('options_delivery', e)}
           checked = {this.state.options_delivery_state}
           onClick={() => this.setState({options_delivery_state: !this.state.options_delivery_state })}
           />
           <label className="form-check-label" htmlFor="options_delivery">
             Delivery
           </label>
         </div>

       </div>
       <input type="submit" value="Filter" className="btn filter-btun btn-lg"/>
     </form>
        </div>
        <div className="col-md-10">
        <div className="restaurants-part">
          <div className="container">
            <div className="restaurants-bdr">
              <div className="res-part-top">
                <h2>Restaurants</h2>
              </div>
              <div className="row">
              {Object.keys(this.state.responseData).length > 0 ?
              this.state.restaurant_data != undefined ?  this.state.restaurant_data.length > 0 ?
              <InfiniteScroll
                  dataLength={this.state.restaurant_data.length}
                  next={() =>this.fetchMoreData()}
                  hasMore={this.state.hasMore}
                  loader={loadingrestaurantsNew}
                >
                <div className="row">
                {itemlist}
                </div>
              </InfiniteScroll>
               :
              (<div className="data-not-found">
                      <h3>No Restaurant Found</h3>
                  </div>):(<div className="data-not-found">
                    <h3>No Restaurant Found</h3></div>
                )
                 : (
                   <div className="row loading-row">
                       {Array.apply(null, Array(12)).map(function(item, i){
                           return (
                             <div className="col-lg-4 col-md-6" key={i}>
                             <div className="loader-img">
                             <img src="/images/DiningTekLoader.gif" alt="images not found" className="img-fluid" />
                             </div>
                             </div>
                           );
                       }, this)}
                   </div>
                ) }
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        {/* Restaurants-area end */}
        {/* pagination-area start */}
        {
          // <div className="row pagination-content">
          //   <div className="col-md-8"></div>
          //   <div className="col-md-4 pagination-info">
          //   <Pagination
          //   prevPageText='prev'
          //   nextPageText='next'
          //   hideFirstLastPages
          //   activePage={this.state.activePage}
          //   itemsCountPerPage={10}
          //   totalItemsCount={450}
          //   pageRangeDisplayed={5}
          //   onChange={e =>this.handlePageChange(e)}
          // />
          //   </div>
          // </div>
        }

        {/* pagination-area end */}
      </div>

      <Footer />
            </>
        );
    }
}


// const mapStateToProps = state => ({
//   contact: state.contact
// });

// const mapDispatchToProps = dispatch => ({
//   createContact: data => dispatch(createContact(data))
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Restaurant);

export default Restaurant2;
