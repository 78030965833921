import React, { Component } from 'react';
import HeaderTwo from "./HeaderTwo";
import Footer from "./Footer";
class DonationThankYou extends Component {
    render() {
        return (
            <>
                <HeaderTwo />
                <div className = "row">
                    <div className ="container">
                        <div className ="donation-confirmation text-center">
                        <h2>Thank You!</h2>
                        <h2>for making your generous donation!</h2>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default DonationThankYou;
