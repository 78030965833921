import React, { Component } from 'react';
import { Button ,
    Container,
      Row,
      Col,
    Form,
    } from 'react-bootstrap';
import {config} from '../config';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";


class AllCity extends Component{
  constructor(props){
    super(props);
    this.state = {
        allcity : [],
        merchant_token : null
    }

  }
//   componentDidMount(){
//     const url_merchant_token =
//   `${config.api_root}/security/session/merchants?Key=diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq&Secret=ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx&device_id=21212121121212wqwqw`;
// fetch(url_merchant_token, {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//     "Key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
//     "Secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
//   }
// }).then(response => response.json())
//       .then(merchant => {
//         this.setState({
//           merchant_token: merchant.object.access_token
//         });
//       }).then(() =>{
//         const url2 =
//           `${config.api_base}/merchants/business/network/cities`;
//           const bearer = "Bearer" + this.state.merchant_token;
//         fetch(url2, {
//           method: "GET",
//           headers: {
//             'Authorization': bearer
//           }
//         }).then(response => response.json())
//               .then(allcity => {
//                 this.setState({
//                   allcity: allcity.data
//                 })
//               })
//           .catch(error =>
//             this.setState({
//               message: "Something bad happened " + error
//             })
//           );
//       })
//       .catch(error =>
//         this.setState({
//           message: "Something bad happened " + error
//         })
//       );
//
//   }
  render(){
        console.log("allcity",this.state.allcity);
        console.log("allcity merchant",this.state.merchant_token);
    return(
      <section id="cities">
		<div class="container">
			<h2>Cities</h2>
			<div class="row">
				<div class="col-sm-12 col-md-4">
					<div class="cities-list">
						<ul>
            {window.locationsdata && window.locationsdata.length > 0 ? window.locationsdata.slice(0,10).map((city,index) =>{
              return(
                <li key={index}>{city}</li>
              )
            }

          ) : null  }
						</ul>
					</div>
				</div>
				<div class="col-sm-12 col-md-4">
					<div class="cities-list">
						<ul>
            {window.locationsdata && window.locationsdata.length > 0 ? window.locationsdata.slice(11,21).map((city,index) =>{
              return(
                <li key={index}>{city}</li>
              )
            }

          ) : null  }
						</ul>
					</div>
				</div>
				<div class="col-sm-12 col-md-4">
					<div class="cities-list">
						<ul>
            {window.locationsdata && window.locationsdata.length > 0 ? window.locationsdata.slice(22,32).map((city,index) =>{
              return(
                <li key={index}>{city}</li>
              )
            }

          ) : null  }
						</ul>
					</div>
				</div>
			</div>
		</div>

      </section>

    )
  }
}

export default AllCity;
