import React from 'react'
import HeaderTwo from "./HeaderTwo";
import Footer from "./Footer";

function DonationTerms(){
  return(
    <>
    <HeaderTwo />
    <div className="term-condition">
      <div className="container" >
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <h3>DiningTek Restaurant or Food Bank Donation Terms</h3>
              <hr></hr>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
              <p>The following terms apply to any donations made through the DiningTek services, systems, and websites offered by Dining Software Group, Inc.<span className="font-weight-bold">(“DiningTek”, “us”, “we”, and “our”)</span>. If you do not agree to be bound by these terms, promptly exit this page and do not make a donation. </p>

              <h5 className="custom-heading">Selecting a restaurant or food bank for a donation is optional: </h5>
              <p>DiningTek users are under no obligation to donate to a restaurant.   </p>

              <h5 className="custom-heading">Donations can be made with or without ordering food:</h5>
              <p>Donations are made separately from food orders. You do not need to place a food order to make a donation, and donations and food orders must be made separately. Some of DiningTek’s restaurants, food banks, and community meal service organizations are only taking donations and do not currently have food ordering capabilities.</p>

              <h5 className="custom-heading">Instructions for donating:</h5>
              <p>Select the restaurant or food bank you want to donate to from the available list of organizations by hovering your curser over the organization’s picture or icon and click the “Donate” button. Follow the instructions on the donations page -- complete your payment and contact information and indicate the donation amount before clicking on the “Donate Now” button to process your donation. Restaurants or food banks will receive information about users who donate to them, such as name and email. We cannot offer anonymous donations at this time. Users will then receive a confirmation email once their donation has been processed. Food orders must be placed in a separate transaction. </p>

              <h5 className="custom-heading">Donations are non-refundable and may not be tax-deductible charitable contributions:</h5>
              <p>Donations made through our systems are final once processed and are non-refundable. Donations made to restaurants through the DiningTek platform are not considered charitable contributions for federal tax purposes.  Donations made to food banks may be charitable donations for federal tax purposes. However, DiningTek makes no representations or warranties regarding the same and neither DiningTek nor the organization will provide documentation of donations for tax purposes other than the electronic confirmation of the donation. If you require documentation of your donation you must keep an electronic copy or print a paper copy of the electronic confirmation of your donation. </p>

              <h5 className="custom-heading">Users cannot choose how donated funds are used:</h5>
              <p>Restaurants and food banks have full discretion to use any donations processed through DiningTek in any manner they choose. Users cannot specify how funds may be used or the use of such funds in any way. </p>

              <h5 className="custom-heading">DiningTek Transaction Fees:</h5>
              <p>DiningTek will deduct a transaction fee of 2.9% from each donation in addition to a flat, $0.30 processing fee. Restaurants will receive the dollar amount of the donation minus the transaction fee and processing fee. </p>

              <h5 className="custom-heading">Use of DiningTek Donation Services:</h5>
              <p>Making donations through DiningTek is restricted to parties that can lawfully enter into and form contracts under applicable law, and who are over the age of 18. For additional information about DiningTek’s services, please see our Terms & Conditions and Privacy Policy. </p>

          </div>
        </div>
        </div>
      </div>
    <Footer />
    </>
  )
}

export default DonationTerms;
