import React, { Component } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
// import { geolocated } from "react-geolocated";
//import '../css/bootstrap-3.3.4-dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
class BelowHeader extends Component {
    constructor(props){
        super(props);
        this.state ={
            address: '',
            errorMessage: '',
            latitude: null,
            longitude: null,
            isGeocoding: false,
            current_address:'',
            searchbutton:false
        };


    }
    componentDidMount() {
   }
  getMyLocation = (e) => {
    if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
              console.log(position.coords.latitude);
              console.log(position.coords.longitude);
              this.setState({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              });
              if (this.state.latitude !== null && this.state.longitude !== null) {
                fetch(
                  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude},${this.state.longitude}&sensor=false&key=AIzaSyAbGLWqhmWGpFt8Ppc8WSDc8mDfyv2oI84`
                )
                  .then(response => response.json())
                  .then(response => {
                    console.log(response.results[0].formatted_address);
                    this.setState({
                      current_address: response.results[0].formatted_address,
                      searchbutton:true
                    });
                  });
              }
            });
          } else {
            console.error("Geolocation is not supported by this browser!");
          }

};

    handleChange = address => {
        this.setState({
            address,
            current_address:"",
            latitude: null,
            longitude: null,
            errorMessage: '', });
      };
      handleSelect = selected => {
        this.setState({ isGeocoding: true, address: selected,current_address: selected });
        geocodeByAddress(selected)
          .then(res => getLatLng(res[0]))
          .then(({ lat, lng }) => {
            this.setState({
              latitude: lat,
              longitude: lng,
              isGeocoding: false,
              searchbutton:true
            });
          })
          .catch(error => {
            this.setState({ isGeocoding: false });
            console.log('error', error); // eslint-disable-line no-console
          });
      };

      handleCloseClick = () => {
        this.setState({
          address: '',
          latitude: null,
          longitude: null,
          current_address:''
        });
      };

      handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
          clearSuggestions();
        });
      };



    render() {
      const searchOptions = {
        componentRestrictions: {country: "us"}
}
      console.log("header location" , this.state);
      const {
            address,
          } = this.state;

          const isObject = val => {
            return typeof val === 'object' && val !== null;
          };

          const classnames = (...args) => {
            const classes = [];
            args.forEach(arg => {
              if (typeof arg === 'string') {
                classes.push(arg);
              } else if (isObject(arg)) {
                Object.keys(arg).forEach(key => {
                  if (arg[key]) {
                    classes.push(key);
                  }
                });
              } else {
                throw new Error(
                  '`classnames` only accepts string or object as arguments'
                );
              }
            });

            return classes.join(' ');
          };

        return (

          <div className="bd-example">
           <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel" data-interval="4000">
             <ol className="carousel-indicators">
               <li data-target="#carouselExampleCaptions" data-slide-to={0} className="active" />
               <li data-target="#carouselExampleCaptions" data-slide-to={1} />
               <li data-target="#carouselExampleCaptions" data-slide-to={2} />
               <li data-target="#carouselExampleCaptions" data-slide-to={3} />
               <li data-target="#carouselExampleCaptions" data-slide-to={4} />
             </ol>
             <div className="carousel-inner">
               <div className="carousel-item active">
                 <img src="/images/banner1.png" className="d-block w-100" alt="..." />

               </div>
               <div className="carousel-item">
                 <img src="/images/banner2.png" className="d-block w-100" alt="..." />

               </div>
               <div className="carousel-item">
                 <img src="/images/banner3.png" className="d-block w-100" alt="..." />

               </div>
               <div className="carousel-item">
                 <img src="/images/banner4.png" className="d-block w-100" alt="..." />

               </div>
               <div className="carousel-item">
                 <img src="/images/banner5.png" className="d-block w-100" alt="..." />

               </div>
             </div>
             <div className="carousel-caption">
             <h3 className ="hungry-heading">Hungry?</h3>
                        <p>Order food from favorite restaurants near you.</p>
                        <div className="Hungary-form">

        <PlacesAutocomplete
          onChange={this.handleChange}
          value={this.state.current_address != '' ? this.state.current_address : address}
          onSelect={this.handleSelect}
         onError={this.handleError}
          searchOptions={searchOptions}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="search-bar-container">
               <div className="search-input-container">
                  <input
                   {...getInputProps({
                      placeholder: 'Search Places...',
                      className: 'search-input',
                    })}
                  />
                  {this.state.current_address.length === 0 && address.length === 0 ?
                 (
                  <button
                  className="Locate-button"
                  onClick={this.getMyLocation}
                  >
                    Locate me
                    </button>
                  ):
                   (
                    <button
                      className="clear-button"
                      onClick={this.handleCloseClick}
                    >
                      Clear
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames('suggestion-item', {
                        'suggestion-item--active': suggestion.active,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                         <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}

                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
      {this.state.searchbutton ?
                          (<Link
                            to={{
                               pathname: "/restaurants",
                                location_data: this.state
                            }}
                            > <input type="submit" value="FIND FOOD" /> </Link>) : (<input type="submit" value="FIND FOOD" />)}
                 </div>
             </div>
             <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
               <span className="carousel-control-prev-icon" aria-hidden="true" />
               <span className="sr-only">Previous</span>
             </a>
             <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
               <span className="carousel-control-next-icon" aria-hidden="true" />
               <span className="sr-only">Next</span>
             </a>
           </div>
         </div>


        )
    }
}
// export default geolocated({
//   positionOptions: {
//       enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(BelowHeader);

export default BelowHeader;
