import React,{ Component } from 'react';
import Header from './components/Header';
import BelowHeader from './components/BelowHeader';
import HeaderSlider from './components/HeaderSlider';
import Service from './components/Service';
import FavoriteRestaurant from './components/FavoriteRestaurant';
import NewRestaurant from './components/NewRestaurant';
import NewRestaurant2 from './components/NewRestaurant-bak';
import PopularRestaurant from './components/PopularRestaurant';
import Footer from './components/Footer';
import AllCity from './components/AllCity';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : ""
    }
  }
  callbackFunction = (value1,value2) => {
    this.setState({
      user_email: value1,
      access_token : value2
    },()=>{
      this.sendDataApp(this.state.user_email,this.state.access_token);
    })
}

sendDataApp = (value_email,value_accesstoken) => {
  this.props.parentCallbackRoot(value_email,value_accesstoken);
}
  render() {
    console.log("email",this.state.user_email);
    console.log("access_token",this.state.access_token);
    return (
          <div className="App">
            <Header parentCallback = {this.callbackFunction}/>

            <HeaderSlider />
            <Service />
            {
              // <FavoriteRestaurant />
            }

            <NewRestaurant />
            {
              // <PopularRestaurant />
            }


               <AllCity />


            <Footer />
          </div>
        );
  }
}


export default App;
