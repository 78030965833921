import React from 'react'
import HeaderTwo from "./HeaderTwo";
import Footer from "./Footer";

function PrivacyPolicy(){
  return(
    <>
    <HeaderTwo />
    <div className="term-condition">
      <div className="container" >
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <h3>Privacy Policy</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
               <h5 className="custom-heading return">Return Policy: </h5>
                <div className="row return-policy">
                     <div className="col-md-1"></div>
                     <div className="col-md-11">
                            <h5 className="custom-heading">Cash Purchases </h5>
                            <p>If yomu choose cash as your method of payment then please call or visit the restaurant, DiningTek is not responsible for that transaction. </p>

                             <h5 className="custom-heading">Credit Card Purchases</h5>
                             <p>By choosing the credit card option, you are agreeing that you are authorized to use the credit card being used in the purchase. You must include all requested information to process the payments, if DiningTek does not receive payment from your credit card company, you agree to pay to pay DiningTek the amount due.</p>

                              <h5 className="custom-heading">Incorrect Orders</h5>
                              <p>
                              If an order is not correct, please contact the restaurant you are ordering from Immediately to let them know of the mistake.
                              If you are unsatisfied with your order, please email <a href="mailto:support@restaurantbite.com">support@DiningTek.com</a> for assistance. Please include all information regarding your order, including order number, restaurant name, date, and amount of the order, the order, and the order mistake.
                              </p>

                              <h5 className="custom-heading">Delivery</h5>
                              <p><b>If an issue with your order arises, please contact the restaurant you ordered from immediately.</b> DiningTek is not responsible for any issues that may arise during the delivery process (up to and including orders which have not been delivered).
                               The restaurant you ordered from or delivery service company is responsible for delivering the food to customers. The risk of loss transfers to you upon our delivery of the appropriate information to the carrier; you must file any claims directly with the restaurant or delivery service.
                              </p>
                      </div>
                </div>
          </div>
        </div>
        </div>
      </div>
    <Footer />
    </>
  )
}

export default PrivacyPolicy;
