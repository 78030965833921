import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Button ,
    Container,
      Row,
      Col,
    Form,
    } from 'react-bootstrap';
import {config} from '../config';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";


class ForgetPassword extends Component{
  constructor(props){
    super(props);
    this.state = {
      email : null,
      forget_info : [],
      forget_Process :false
    }
    this.ForgetPasswordInformation = this.ForgetPasswordInformation.bind(this);
  }
  OnHandleFieldChange = (field, event) => {
      const new_state = {};
      new_state[field] = event.target.value;
      this.setState(new_state);
  };

  ForgetPasswordInformation(event){
    event.preventDefault();
      const logindata = {
        "email" : this.state.email,
      }
      console.log("data first name",logindata.first_name);
      const url2 =
        `${config.api_root}/security/session/users/password/rescue_app`;
      fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          form_id: "",
          user_id: logindata.email
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => response.json())
            .then(login => {
              this.setState({
                forget_info: login.object
              })
              if(login.request_status) {
                  this.setState({
                    forget_Process : true
                  })
                  localStorage.setItem('forget_token', login.object.token);
                  localStorage.setItem('forget_user_email', this.state.email);
              }
            })
        .catch(error =>
          this.setState({
            message: "Something bad happened " + error
          })
        );
  }
  render(){
    if(this.state.forget_Process === true){
        return <Redirect to="/changepassword" />;
    }

    return(
      <>
        <Header />
        <div className = "container">
          <div className = "row ">
              <div className = "col-md-2"></div>
              <div className = "col-md-8 ">
              <Form className="signup-content" onSubmit = {this.ForgetPasswordInformation}>
                  <div className = "container">
                  <h1>Forget Password</h1>
                  {this.state.forget_info.error ? (<p>{this.state.forget_info.error}</p>) : null}
                  <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email"  value = {this.state.email} onChange = {e => this.OnHandleFieldChange('email', e)} required/>
                  </Form.Group>
                  <Button type="submit" className = "register-form-button">
                  Send OTP
                  </Button>
                  </div>
              </Form>
                </div>
              <div className = "col-md-2"></div>
          </div>
        </div>

        <Footer />
        </>
    )
  }
}

export default ForgetPassword;
