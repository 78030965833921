import React from 'react'
import HeaderTwo from "./HeaderTwo";
import Footer from "./Footer";

function TermAndCondition(){
  return(
    <>
    <HeaderTwo />
    <div className="term-condition">
      <div className="container" >
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <h3>Privacy Policy DiningTek</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
          <div>

<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,


  }}
>
  <span>
    <span>
      Effective Date: July 16, 2020
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <span>
      DiningTek is committed to keeping your Personal Information private and
      secure. This Privacy Policy describes what Personal Information the
      DiningTek platform and diningtek.com (the “website,” “us,” “our” and
      “we”) collects and how we use it. DiningTek, diningtek.com, and the
      DiningTek services are provided by Dining Software Group, Inc.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <span>
      &nbsp;“Personal Information” as used in this policy means information
      that relates to and identifies you, and as defined by any applicable
      privacy laws. If you have any questions or comments regarding this
      Privacy Policy, please contact us at support@diningtek.com
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,


  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          Changes to Our Privacy Policy
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <span>
      We may make changes to the Privacy Policy from time to time. We will
      notify you of any material changes by posting a notice on the website.
      Please review any changes to our Privacy Policy carefully to ensure you
      understand our practices and procedures.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,


  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          Information We Collect and Receive
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify"
  }}
>
  <span>
    <span>
      We collect Personal Information about you when you visit our website or
      any restaurant websites that we operate, when you place an order with
      us, and when you communicate with us through email, telephone, website
      form, or otherwise. The Personal Information we collect includes:
    </span>
  </span>
</p>
<ul style={{ listStyleType: "disc", marginLeft: "0in", fontSize:18 }}>
  <li>
    <span>
      <span

      >
        Order information, including name, delivery and/or billing address,
        phone number, payment information, order contents, restaurant,
        delivery time, and any other information provided by you concerning
        you order.
      </span>
    </span>
  </li>
  <li>
    <span>
      <span

      >
        Account information, including name, password, email address, and
        residential address.
      </span>
    </span>
  </li>
  <li>
    <span>
      <span

      >
        Name, address, email, other contact information and any other
        information you provide when you contact us regarding our company or
        our services.
      </span>
    </span>
  </li>
  <li>
    <span>
      <span

      >
        General website statistics and usage information including your IP
        address, page views, type of web browser, GPS, and operating system,
        duration of your visits, and information about the content and pages
        you view and the features you access on the website.
      </span>
    </span>
  </li>
  <li>
    <span>
      <span

      >
        Any other information you send to use through communications including
        email, telephone, website form, or otherwise.
      </span>
    </span>
  </li>
</ul>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify"
  }}
>
  <span>
    <u>
      <span

      >
        Cookies
      </span>
    </u>
    <u>
      <span

      >
        .
      </span>
    </u>
    <span>
      &nbsp;This website uses cookies to collection information about how you
      use the website. A cookie is a small file containing a string of
      characters that is sent to your computer when you visit a website. When
      you visit the website again, the cookie allows that website to recognize
      your browser. Cookies may store unique identifiers, user preferences,
      and other information.
    </span>
    <span style={{ fontFamily: '"Times New Roman", serif' }}>&nbsp;</span>
    <span>
      You can set your browser to refuse all cookies or to indicate when a
      cookie is being sent, however, some website features or services may not
      function properly without cookies.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify"
  }}
>
  <span>
    <u>
      <span

      >
        Do Not Track Signals.
      </span>
    </u>
    <strong>
      <span

      >
        &nbsp;&nbsp;
      </span>
    </strong>
    <span>
      This website does not respond to Do Not Track (“DNT”) signals.
      Third-party applications and plugins, such as social media integration,
      may treat DNT signals differently.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        No Information from Children Under Age 18
      </span>
    </u>
    <span>
      . This website is not directed to children under the age of 18. If we
      learn that we have collected Personal Information from a child under the
      age of 18, we will promptly delete that information. If you believe we
      have collected Personal Information from someone under age 18,
      please&nbsp;
    </span>
    <a href="http://www.canvasondemand.com/help/contact-us">
      <span
        style={{
          fontFamily: '"Times New Roman", serif',
          color: "black",
          textDecoration: "none"
        }}
      >
        email us
      </span>
    </a>
    <span>
      &nbsp;at support@diningtek.com, or call us at (720) 648 - 4528.&nbsp;
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,


  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          How We Use Your Personal Information
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <span>
      DiningTek primarily provides online ordering platforms, mobile
      applications, and marketing services for the restaurant and hospitality
      industry. Most of the Personal Information we collect is for processing
      orders that customers place with our restaurant clients.
    </span>
  </span>
</p>
<ul style={{ listStyleType: "disc", marginLeft: "0in", fontSize:18 }}>
  <li>
    <span>
      <strong>
        <span style={{ fontFamily: '"Times New Roman","serif"' }}>
          Order Information
        </span>
      </strong>
      <span style={{ fontFamily: '"Times New Roman","serif"' }}>
        : We use order information to process your orders, donations, and
        payments, and to communicate information to our restaurant clients so
        that they can fulfill your orders.
      </span>
    </span>
  </li>
  <li>
    <span>
      <strong>
        <span style={{ fontFamily: '"Times New Roman","serif"' }}>
          Contact Information
        </span>
      </strong>
      <span style={{ fontFamily: '"Times New Roman","serif"' }}>
        : We use the contact information we collect about you for our
        communications regarding our company and services.
      </span>
    </span>
  </li>
  <li>
    <span>
      <strong>
        <span style={{ fontFamily: '"Times New Roman","serif"' }}>Other</span>
      </strong>
      <span style={{ fontFamily: '"Times New Roman","serif"' }}>
        : For all website visitors, we use your Personal Information to
        improve and maintain our website and services.
      </span>
    </span>
  </li>
</ul>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,


  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          When We Disclose Information
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        Disclosure of Order Information
      </span>
    </u>
    <span>
      . We process food and services orders on behalf of our restaurant and
      hospitality clients and will disclose information related to those
      orders to such clients so that they may properly fulfill such orders. If
      you contact DiningTek regarding your order or any issues with your
      order, we may also disclose some or all of those communications to the
      business about which you are calling so that we may troubleshoot and/or
      resolve your issue.{" "}
      <em>
        Please review each business’s privacy notice for any information
        concerning their use of your Personal Information before placing an
        order. This Privacy Policy does not apply to the use of your Personal
        Information by any of our restaurant clients after we have informed
        them of your order.
      </em>
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        Disclosure to Third Party Service Providers
      </span>
    </u>
    <span>
      . &nbsp;We engage third-party service providers to assist in providing
      data storage, website hosting, analytics, and other services. Personal
      Information is hosted on their servers and services on our behalf and is
      kept confidential and limited to DiningTek’s use. DiningTek uses
      industry standard third-party payment processors to process orders, and
      discloses your payment information solely for the purpose of processing
      authorized payments.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        Disclosures for Legal Requirements
      </span>
    </u>
    <span>
      . We may disclose your Personal Information to respond to legal
      requirements, to protect or enforce our rights and policies, to protect
      or enforce the rights of a third party, to prevent harm to a person or
      as required or permitted by law, including, without limitation, to
      comply with a subpoena or court order.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        Disclosure of Non-Personal Information
      </span>
    </u>
    <span>
      . We may disclose anonymized or de-identified information to any party
      including potential business partners, advertisers, investors, users,
      and other third parties.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        Disclosure to Corporate Affiliates and Successors
      </span>
    </u>
    <span>
      . DiningTek may disclose Personal Information and other data to its
      parent, subsidiaries or other affiliates, if any. &nbsp;If DiningTek is
      part of a merger, acquisition, reorganization, sale of some or all of
      its assets, or similar transaction, or takes steps in contemplation of
      such transactions (e.g., due diligence), Personal Information and other
      data may be shared or transferred with a new or prospective owner,
      subject to standard confidentiality agreements. &nbsp;
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        Disclosure with Your Consent
      </span>
    </u>
    <u>
      <span

      >
        .
      </span>
    </u>
    <span>
      &nbsp;In addition to the sharing described elsewhere in this Privacy
      Policy, we may share Personal Information with third parties if we
      obtain your consent to do so.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <u>
      <span

      >
        Disclosure to Analytics and Advertising Partners
      </span>
    </u>
    <span>
      . We use analytics cookies and services including, but not limited to,
      Google Analytics, to understand our website visits and use. For more
      information about how Google Analytics uses your information, please see
      https://www.google.com/policies/privacy/partners/.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "0in",
    marginRight: "0in",
    marginBottom: "7.5pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify"
  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          California Shine the Light Rights
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "0in",
    marginRight: "0in",
    marginBottom: "7.5pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify"
  }}
>
  <span>
    <span>
      California’s “Shine the Light” law permits California residents to
      request certain information regarding our disclosure of Personal
      Information to third parties for their direct marketing purposes. To
      obtain this information, please send an email message to
      support@diningtek.com with “Shine the Light Request” on the subject line
      and in the body of your message. We will provide the requested
      information to you at your e-mail address in response. Please be aware
      that not all information is covered by the "Shine the Light"
      requirements and only information on covered sharing (if any) will be
      included in our response.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,
    fontFamily: '"Calibri","sans-serif"'
  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          Storage and Retention
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify"
  }}
>
  <span>
    <span>
      Your Personal Information is stored by DiningTek on our servers and on
      the servers of the cloud-based data storage providers that we engage.
      DiningTek retains Personal Information for the duration of our business
      relationship with you, for the purposes indicated in this Privacy
      Policy, and for such other periods as required by law, which may be
      indefinitely.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          Data Security
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <span>
      DiningTek takes the privacy of your information seriously. We use
      industry standard physical, technical and administrative security
      measures and safeguards to protect the confidentiality and security of
      Personal Information. However, since the Internet is not a 100% secure
      environment, we cannot guarantee the security of any information you
      transmit to us. Further, it is your responsibility to protect the
      security of your account and login information. Please note that content
      you store or transmit through the website, or e-mails and other
      communications you send to us, may not be encrypted.&nbsp;
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,


  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          Third Party Websites
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <span>
      You may be able to access third-party websites directly from this
      website. This Privacy Policy does not apply when you access third-party
      websites. We cannot control how third parties may use any information
      you disclose to them, so you should carefully review the privacy policy
      of any third-party website you visit before using it or disclosing your
      information to its provider.
    </span>
  </span>
</p>
<p
  style={{
    marginTop: "12.0pt",
    marginRight: "0in",
    marginBottom: "12.0pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,


  }}
>
  <span>
    <strong>
      <u>
        <span

        >
          Questions and How to Contact Us
        </span>
      </u>
    </strong>
  </span>
</p>
<p
  style={{
    marginTop: "0in",
    marginRight: "0in",
    marginBottom: ".0001pt",
    marginLeft: "0in",
    lineHeight: "normal",
    fontSize: 19,

    textAlign: "justify",

  }}
>
  <span>
    <span>
      If you have any questions or concerns regarding our Privacy Policy or
      practices, including to exercise any rights you may have, or otherwise
      need to contact us regarding your Personal Information, please contact
      us by emailing support@diningtek.com or calling (720) 648-4528.
    </span>
  </span>
  <span style={{ fontSize: 24, fontFamily: '"Times New Roman", serif' }}>
    &nbsp;
  </span>
</p>
</div>

          </div>
        </div>
        </div>
      </div>
    <Footer />
    </>
  )
}

export default TermAndCondition;
