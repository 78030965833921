import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Button ,
    Container,
      Row,
      Col,
    Form,
    } from 'react-bootstrap';
import {config} from '../config';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

const recaptchaRef = React.createRef();

class Signup extends Component{
  constructor(props){
    super(props);
    this.state = {
      firstname : null,
      lastname : null,
      email : null,
      city : null,
      password : null,
      error_message:null,
      recaptcha_value:null,
      register_info : [],
      otp_info:[],
      register_Process :false
    }
    this.Registerinformation = this.Registerinformation.bind(this);
  }
  OnHandleFieldChange = (field, event) => {
      const new_state = {};
      new_state[field] = event.target.value;
      this.setState(new_state);
  };

  Registerinformation(event){
    event.preventDefault();
     if(this.state.recaptcha_value != null){

      const data = {
        "first_name" : this.state.firstname,
        "last_name" : this.state.lastname,
        "city" : this.state.city,
        "email" : this.state.email,
        "password" :this.state.password
      }
      console.log("data first name",data.first_name);
      const url1 =
        `${config.api_base}/users/register?access_token=eyJhbGciOiJIUzUxMiJ9.eyJhY2Nlc3NfdG9rZW4iOiIxNGY5ZDYzZS0xZDVkLTRhYjYtYWMyNi0zNDdlYjhkMWE3Y2EiLCJzdWIiOiJvcmcuc3ByaW5nZnJhbWV3b3JrLnNlY3VyaXR5LmNvcmUudXNlcmRldGFpbHMuVXNlckBjYmU5YjVkYTogVXNlcm5hbWU6IHRpZmZpbjsgUGFzc3dvcmQ6IFtQUk9URUNURURdOyBFbmFibGVkOiB0cnVlOyBBY2NvdW50Tm9uRXhwaXJlZDogdHJ1ZTsgY3JlZGVudGlhbHNOb25FeHBpcmVkOiB0cnVlOyBBY2NvdW50Tm9uTG9ja2VkOiB0cnVlOyBOb3QgZ3JhbnRlZCBhbnkgYXV0aG9yaXRpZXMiLCJhdWRpZW5jZSI6IndlYiIsImNyZWF0ZWQiOjE1MjY1NDExMzUyNjUsImV4cCI6Nzc2NjI4MTE1Nzk5MzM3Nn0.iBYnE8GECEHDNjm7rXPV72VaYSOBkAyBg_woOBn3DeDOV7p-RU-KjnECFUnntZHW6qBYYyZGZnrMMIrESoKM5g`;
      fetch(url1, {
        method: "POST",
        body: JSON.stringify({
              fields: {
                registrationEmail: data.email,
                registrationFirstName: data.first_name,
                registrationLastName: data.last_name,
                registrationPassword: data.password
              },
              form_id: ""
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => response.json())
            .then(register => {
              // localStorage.setItem('access_token', 'eyJhbGciOiJIUzUxMiJ9.eyJhY2Nlc3NfdG9rZW4iOiIxNGY5ZDYzZS0xZDVkLTRhYjYtYWMyNi0zNDdlYjhkMWE3Y2EiLCJzdWIiOiJvcmcuc3ByaW5nZnJhbWV3b3JrLnNlY3VyaXR5LmNvcmUudXNlcmRldGFpbHMuVXNlckBjYmU5YjVkYTogVXNlcm5hbWU6IHRpZmZpbjsgUGFzc3dvcmQ6IFtQUk9URUNURURdOyBFbmFibGVkOiB0cnVlOyBBY2NvdW50Tm9uRXhwaXJlZDogdHJ1ZTsgY3JlZGVudGlhbHNOb25FeHBpcmVkOiB0cnVlOyBBY2NvdW50Tm9uTG9ja2VkOiB0cnVlOyBOb3QgZ3JhbnRlZCBhbnkgYXV0aG9yaXRpZXMiLCJhdWRpZW5jZSI6IndlYiIsImNyZWF0ZWQiOjE1MjY1NDExMzUyNjUsImV4cCI6Nzc2NjI4MTE1Nzk5MzM3Nn0.iBYnE8GECEHDNjm7rXPV72VaYSOBkAyBg_woOBn3DeDOV7p-RU-KjnECFUnntZHW6qBYYyZGZnrMMIrESoKM5g');
              // localStorage.setItem('user', this.state.email);
              this.setState({
                register_info: register.object,
              },() =>{
                if(this.state.register_info.state){
                  this.setState({
                    register_Process :true
                  })
                  console.log("submit-------------");
                  const otp_url =
                    `${config.api_root}/security/session/users/verify/otp`;
                  fetch(otp_url, {
                    method: "POST",
                    body: JSON.stringify({
                      otp: 111111,
                    state: this.state.register_info.state,
                      user: this.state.email
                    }),
                    headers: {
                      "Content-Type": "application/json",
                      "device_id" : "21212121121212wqwqw",                //device id should be unique every time
                      "key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
                      "secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
                    }
                  }).then(response => response.json())
                        .then(otp => {
                          console.log("not submit-------------");
                          this.setState({
                            otp_info: otp
                          });
                        })
                }


              });
            })
        .catch(error =>
          this.setState({
            message: "Something bad happened " + error
          })
        );
     }


    }

    RecaptchaHandler = () =>{
      const recaptchaValue = recaptchaRef.current.getValue();
      this.setState({
        recaptcha_value : recaptchaValue
      })
      setInterval(() => {
        this.setState({
          recaptcha_value: null
        })
      }, 120000);
      console.log("recaptchaValue",recaptchaValue);
    }
  render(){

    let local_user = localStorage.getItem('user');
    let local_token = localStorage.getItem('access_token');

    return(
      <>
        <Header />
        <div className = "container">
          <div className = "row ">
              <div className = "col-md-2"></div>
              <div className = "col-md-8 ">
              {this.state.register_Process == false ? (
              <Form className="signup-content" onSubmit = {this.Registerinformation}>
                  <div className = "container">
                  <h1>Sign Up</h1>
                  {this.state.register_info.error ? (<p>{this.state.register_info.error}</p>) : null}
              <Form.Group controlId="formBasicName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text"  value = {this.state.firstname} onChange = {e => this.OnHandleFieldChange('first_name', e)} required/>
                  </Form.Group>
                  <Form.Group controlId="formBasicLname">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text"  value = {this.state.lastname} onChange = {e => this.OnHandleFieldChange('last_name', e)} required />
                  </Form.Group>
                  <Form.Group controlId="formBasicCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control type="text"  value = {this.state.city} onChange = {e => this.OnHandleFieldChange('city', e)} required/>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email"  value = {this.state.email} onChange = {e => this.OnHandleFieldChange('email', e)} required/>
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password"  value = {this.state.password} onChange = {e => this.OnHandleFieldChange('password', e)} required />
                  </Form.Group>
                  {/* <Form.Group controlId="formBasicCpassword">
                      <Form.Control type="password" placeholder="Confirm Password" value = {this.state.c_password} onChange = {e => this.OnHandleFieldChange('c_password', e)} required/>
                  </Form.Group> */}
                  <Form.Group controlId="formBasicChecbox">
                      <Form.Check type="checkbox" label={<>I Agree to the <Link to={'/termandcondition'}>Terms & Conditions</Link> </>} />
                  </Form.Group>
                  <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={config.site_key}
                        onChange={this.RecaptchaHandler}
                    />
                  <Button type="submit" className = "register-form-button">
                  Register
                  </Button>
                  </div>
              </Form>)
              : (<div className ="thanku_registration"><h2>Thank you for registration !!</h2>
                  <h4>Now you can logIn</h4>
              </div>)}
                </div>
              <div className = "col-md-2"></div>
          </div>
        </div>

        <Footer />
        </>
    )
  }
}

export default Signup;
