import React, { Component } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from "react-router-dom";
import {config} from '../config';
import ReactTooltip from 'react-tooltip';
import Pagination from "react-js-pagination";
import InfiniteScroll from 'react-infinite-scroll-component';
class NewRestaurant2 extends Component {
    constructor(props){
        super(props);
        this.state ={
            restaurant_data :[],
            responseData:null,
            merchant_token:null,
            activePage: 0,
            hasMore: true,
            total_restaurant:0
        }
    }

    // handlePageChange(pageNumber) {
    //    console.log(`active page is ${pageNumber}`);
    //    this.setState({
    //      activePage: pageNumber
    //    },() =>{
    //      const  url = `${config.api_base}/merchants/shop/search?distance_min=0&distance_max=20&page=${this.state.activePage - 1}&size=10&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
    //      const bearer = "Bearer" + this.state.merchant_token;
    //      fetch(url, {
    //              method: 'GET',
    //              headers: {
    //                  'Authorization': bearer,
    //                  'Content-Type': 'application/json'
    //              }
    //          }).then((response) => response.json())
    //            .then((responseData) => {
    //                console.log("search results", responseData);
    //              this.setState({
    //                restaurant_data: responseData.data,
    //                responseData:responseData
    //              });
    //            })
    //          .catch(error => this.setState({
    //              message: 'Something bad happened ' + error
    //          }));
    //    });
    //  }

     fetchMoreData = () => {
       if(this.state.restaurant_data.length >= this.state.total_restaurant){
         this.setState({hasMore: false})
       }
       else{
         const  url = `${config.api_base}/merchants/shop/search?distance_min=0&distance_max=20&type=RESTAURANTS,MOBILE_RESTAURANTS&page=${this.state.activePage + 1}&size=12&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
         const bearer = "Bearer" + this.state.merchant_token;
         fetch(url, {
                 method: 'GET',
                 headers: {
                     'Authorization': bearer,
                     'Content-Type': 'application/json'
                 }
             }).then((response) => response.json())
               .then((responseData) => {
                   console.log("search results", responseData);
                 this.setState({
                   restaurant_data: this.state.restaurant_data.concat(responseData.data),
                   responseData:responseData,
                   total_restaurant:responseData.total
                 },() =>{
                   this.setState({
                     activePage:this.state.activePage + 1
                   })
                 });
               })
             .catch(error => this.setState({
                 message: 'Something bad happened ' + error
             }));
       }

  };
    componentDidMount(){
      const url_merchant_token =
    `${config.api_root}/security/session/merchants?Key=diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq&Secret=ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx&device_id=21212121121212wqwqw`;
  fetch(url_merchant_token, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
      "Secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
    }
  }).then(response => response.json())
        .then(merchant => {
          this.setState({
            merchant_token: merchant.object.access_token
          });
        }).then(() =>{
          const  url = `${config.api_base}/merchants/shop/search?distance_min=0&type=RESTAURANTS,MOBILE_RESTAURANTS&distance_max=20&page=0&size=12&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
      const bearer = "Bearer" + this.state.merchant_token;
      fetch(url, {
              method: 'GET',
              headers: {
                  'Authorization': bearer,
                  'Content-Type': 'application/json'
              }
          }).then((response) => response.json())
            .then((responseData) => {
                console.log("search results", responseData);
              this.setState({
                restaurant_data: responseData.data,
                responseData:responseData,
                total_restaurant:responseData.total
              });
            })
          .catch(error => this.setState({
              message: 'Something bad happened ' + error
          }));
        })
        .catch(error => this.setState({
            message: 'Something bad happened ' + error
        }));


    }
    render() {
        console.log("new restaurant" ,this.state.new_restaurant );
        const loadingrestaurants = (
            <div className="row loading-row">
                {Array.apply(null, Array(12)).map(function(item, i){
                    return (
                      <div className="col-lg-4 col-md-6" key={i}>
                      <div className="loader-img">
                      <img src="/images/DiningTekLoader.gif" alt="images not found" className="img-fluid" />
                      </div>
                      </div>
                    );
                }, this)}
            </div>
        );
        const loadingrestaurantsNew = (
            <div className="row loading-row">
                {Array.apply(null, Array(3)).map(function(item, i){
                    return (
                      <div className="col-lg-4 col-md-6" key={i}>
                      <div className="loader-img">
                      <img src="/images/DiningTekLoader.gif" alt="images not found" className="img-fluid" />
                      </div>
                      </div>
                    );
                }, this)}
            </div>
        );

        // const new_restaurant = this.state.restaurant_data.slice(0,4).map((item,index) =>{
            const new_restaurant = this.state.restaurant_data != undefined ? this.state.restaurant_data.length > 0 ? this.state.restaurant_data.map((item,index) =>{
            const restaurant_link = `/restaurant/${item.merchant_id ? item.merchant_id : null}`;

            return(
              <div className="col-lg-4 col-md-6" key={index}>
        <div className="restaurant-item">
        {item.outletShop == true ?(
            <a href={item.business.OUTLET_ENDPOINT}><img src={item.business.LOGO} alt="images not found" className="img-fluid" /></a>
          ):item.business && item.business.ECOMMERCE_STATUS == "false" ? (
            <img src={item.business.LOGO} alt="images not found" className="img-fluid" />
          ):(<Link
                  to={{
                      pathname: `/restaurant/${item.merchant_id}`,
                     menuinfo: item.business.menu, restaurantinfo: item// your data array of objects
                  }}
                  ><img src={item.business.LOGO} alt="images not found" className="img-fluid" />
              </Link>)}
          <div className="restaurant-bdr">
            <div className="restaurant-content">
            {item.outletShop == true ?(
                <a href={item.business.OUTLET_ENDPOINT}><h4>{item.name} - {item.city}</h4></a>
              ):item.business && item.business.ECOMMERCE_STATUS == "false" ? (
                <h4>{item.name} - {item.city}</h4>
              ):(<Link
                    to={{
                        pathname: `/restaurant/${item.merchant_id}`,
                        restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                    }}
                    >
                    <h4>{item.name} - {item.city}</h4>
                    </Link>)}
              <div className="dt-type">{item.tagLine}</div>
              <div className="dt-infobox">
              {item.outletShop == true ?(
                <div className="dt-info">
                    <div className="dt-rating dt-ratingcolor">
                      <i style={{fontStyle: 'normal'}}>★</i>&nbsp;<span>{item.business.rating === 0 ? 5 : item.business.rating }</span>
                    </div>
                    <div>•</div>
                    <div>
                    {item.business.MERCHANT_ADD_FEAT_PICKUP_TIME}
                    </div>
                    <div>•</div>
                    <div className="nVWSi">
                    {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? 'OPEN' : 'CLOSED'}
                    </div>
                </div>
              ):item.business && item.business.ECOMMERCE_STATUS == "true" ? (
                <div className="dt-info">
                    <div className="dt-rating dt-ratingcolor">
                      <i style={{fontStyle: 'normal'}}>★</i>&nbsp;<span>{item.business.rating === 0 ? 5 : item.business.rating }</span>
                    </div>
                    <div>•</div>
                    <div>
                    {item.business.MERCHANT_ADD_FEAT_PICKUP_TIME}
                    </div>
                    <div>•</div>
                    <div className="nVWSi">
                    {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? 'OPEN' : 'CLOSED'}
                    </div>
                </div>
              ):null}
              </div>
              <div className="dt-infobox2"><span className="dt-coupon">10% off on all orders</span></div>
              <div className="dt-boxessurround">
              {item.outletShop == true ? (
                <>
                <div className="_3B2qG">
                <div className="_2ECk4 _24tlh">
                {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? (
                  <a href={item.business.OUTLET_ENDPOINT}>Place Order</a>
                ):(
                  <a href={item.business.OUTLET_ENDPOINT}>View Menu</a>
                )}

                  </div>
                  </div>
                  {item.business && item.business.DONATION == "FALSE" ? null : (
                    <div className="dt-donateinbox">
                        <div className="_2ECk4 _24tlh">
                          <a target="_blank" href={item.business.DONATION_ENDPOINT}>Donate</a>
                        </div>
                    </div>
                  )}
                </>
              ):item.business.ONLY_DONATION == "FALSE" || item.business.ONLY_DONATION === undefined ?
              item.business.DONATION === undefined &&  item.business.ECOMMERCE_STATUS == "true" ? (
              <>
              <div className="_3B2qG">
              <div className="_2ECk4 _24tlh">
              {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? (
                <Link
                      to={{
                          pathname: `/restaurant/${item.merchant_id}`,
                          restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                      }}
                      >
                    Place Order
                      </Link>
              ):(
                <Link
                      to={{
                          pathname: `/restaurant/${item.merchant_id}`,
                          restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                      }}
                      >
                    View Menu
                      </Link>
              )}
                    </div></div>
                <div className="dt-donateinbox">
                    <div className="_2ECk4 _24tlh">
                      <a target="_blank" href={item.business.DONATION_ENDPOINT}>Donate</a>
                    </div>
                </div>
              </>
            ):item.business.DONATION === undefined &&  item.business.ECOMMERCE_STATUS == "false" ?
            (
              <>
              <div className="_3B2qG">
              <div className="_2ECk4 _24tlh">
              <>
              <a
                data-tip
                data-for={item.name}
                data-place="top"
                data-type="dark"
                data-effect="solid"
              >
                Place Order
              </a>
              <ReactTooltip id={item.name}  >
                  <span class="maintenance-content">Currently only accepting donations through DiningTek services.</span>
              </ReactTooltip>
              </>
              </div></div>
                <div className="dt-donateinbox">
                    <div className="_2ECk4 _24tlh">
                      <a target="_blank" href={item.business.DONATION_ENDPOINT}>Donate</a>
                    </div>
                </div>
              </>
            ) :item.business.DONATION == "FALSE" &&  item.business.ECOMMERCE_STATUS == "true" ?(
              <>
              <div className="_3B2qG place-order-btn">
              <div className="_2ECk4 _24tlh">
                {item.business.IS_SHOP_OPEN === 'true' || item.business.IS_SHOP_OPEN === 'TRUE' ? (
                  <Link
                      to={{
                          pathname: `/restaurant/${item.merchant_id}`,
                          restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                      }}
                      >
                    Place Order
                      </Link>
                ):(
                  <Link
                      to={{
                          pathname: `/restaurant/${item.merchant_id}`,
                          restaurantinfo: item,menuinfo: item.business.menu// your data array of objects
                      }}
                      >
                    View Menu
                      </Link>
                )}

                    </div></div>

              </>
            ):item.business.DONATION == "FALSE" &&  item.business.ECOMMERCE_STATUS == "false" ?(
              <></>
            ):null:(
              <>
                <div className="dt-donateinbox donation-btn">
                    <div className="_2ECk4 _24tlh">
                      <a target="_blank" href={item.business.DONATION_ENDPOINT}>Donate</a>
                    </div>
                </div>
              </>
            )}
                </div>
            </div>
          </div>
        </div>
      </div>

            )
        }):null:null
        return (

            <div className="n-restaurants-area">

                <div className="container">
                {
                  // <h4>Check out these New Restaurants!</h4>
                }

                    <h4 className="home-heading">Thank you for supporting local restaurants!</h4>
                    {
                      // <p className="home-heading-2">Please order here online for takeout. Check your city for availablity.</p>
                    }
                      {this.state.restaurant_data != undefined ? this.state.restaurant_data.length > 0 ? null : loadingrestaurants : null}
                        <InfiniteScroll
                            dataLength={this.state.restaurant_data.length}
                            next={() =>this.fetchMoreData()}
                            hasMore={this.state.hasMore}
                            loader={loadingrestaurantsNew}
                          >
                          <div className="row new-restaurants">
                              {new_restaurant}

                          </div>
                        </InfiniteScroll>
                    {
                      // {this.state.restaurant_data != undefined ? this.state.restaurant_data.length > 0 ? null : loadingrestaurants : null}
                      // <div className="row new-restaurants">
                      //     {new_restaurant}
                      //
                      // </div>
                    }

                    {/* pagination-area start */}
                    {
                      // <div className="row pagination-content">
                      //   <div className="col-md-8"></div>
                      //   <div className="col-md-4 pagination-info">
                      //   <Pagination
                      //   prevPageText='prev'
                      //   nextPageText='next'
                      //   hideFirstLastPages
                      //   activePage={this.state.activePage}
                      //   itemsCountPerPage={10}
                      //   totalItemsCount={450}
                      //   pageRangeDisplayed={5}
                      //   onChange={e =>this.handlePageChange(e)}
                      // />
                      //   </div>
                      // </div>
                    }

                    {/* pagination-area end */}
                    {
                      // <h5 className="view-all-btn"><a href="/all-restaurant" className="view-allres">View All Restaurants</a></h5>
                    }

                </div>
    </div>
        );
    }
}

export default NewRestaurant2;
