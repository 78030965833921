// CardSection.js
import React from 'react';
import {CardElement,
  CardNumberElement,
    CardExpiryElement,
    CardCVCElement} from 'react-stripe-elements';

class CardSection extends React.Component {
  render() {
    return (

      <label className="card-section">
        <h2 className = "inner">Card Details</h2>
        <label>
         Card number
         <CardNumberElement
         />
       </label>
       <label>
         Expiration date
         <CardExpiryElement

         />
       </label>
       <label>
         CVC
         <CardCVCElement
         />
       </label>
      </label>
    );
  }
}

export default CardSection;
