import React, { Component } from "react";
import HeaderTwo from "./HeaderTwo";
import HeaderSlider from "./HeaderSlider";
import Footer from "./Footer";
import { config } from "../config";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
class Donation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      telephone: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      postal_code: "",
      state: "122",
      country: "254",
      amount: "0",
      notes_restaurant: "",
      phone_error: false,
      email_error: false,
      lastname_error: false,
      firstname_error: false,
      postal_code_error: false,
      merchant_token: null,
      final_user_email: null,
      final_user_token: null,
      country_info: [],
      state_info: [],
      stripe_info: [],
      stripe_error: null,
      checkout_error: null,
      order_now_click: false,
      payment_token: "",
      payment_complete: false,
      showmodal_cart_empty: false,
      cart_empty_click: true,
      donation_info: [],
      static_resource_endpoint: null,
      static_resource_sufix: null,
      restaurant_info: [],
      radio_amount: "",
      custom_amount: "",
      cardNumber: "",
      cardCvv: "",
      cardExpMonth: "",
      cardExpYear: "",
      heartland_publish_key: "",
      donationTermState: false,
      donationAgree: false,
      requiredfielderror:false,
      donation_payement_info: []
    };
    this.donation = this.donation.bind(this);
  }

  //   sendData = (value_donation_info) => {
  //      this.props.parentCallback(value_donation_info);
  // }

  async componentDidMount() {
    const url_merchant_token = `${config.api_root}/security/session/merchants?Key=diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq&Secret=ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx&device_id=21212121121212wqwqw`;
    fetch(url_merchant_token, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Key: "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
        Secret:
          "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
      }
    })
      .then(response => response.json())
      .then(merchant => {
        this.setState(
          {
            merchant_token: merchant.object.access_token
          },
          () => {
            const user_email =
              localStorage.getItem("user") === null
                ? "guest@onlinebites.com"
                : localStorage.getItem("user");
            const user_token =
              localStorage.getItem("access_token") === null
                ? this.state.merchant_token
                : localStorage.getItem("access_token");
            this.setState({
              final_user_email: user_email,
              final_user_token: user_token
            });

            const url6 = `${config.api_base}/enterprised/countries?access_token=${user_token}&pageSize=250&status=ACTIVE`;
            fetch(url6, {
              method: "GET"
            })
              .then(response => response.json())
              .then(responseData => {
                console.log("search results", responseData);

                this.setState({
                  country_info: responseData.data[0]
                });
                const url7 = `${config.api_base}/enterprised/countries/states?access_token=${user_token}&country_id=254&pageSize=250&status=ACTIVE`;
                fetch(url7, {
                  method: "GET"
                })
                  .then(response => response.json())
                  .then(responseData => {
                    console.log("search results", responseData);

                    this.setState({
                      state_info: responseData.data
                    });
                  });
              })
              .catch(error =>
                this.setState({
                  message: "Something bad happened " + error
                })
              );

            const restId = this.props.match.params.id;
            //  const restId = "ad9b84a54cc879626ab3cfd602e363b6";

            const url_stripe = `${config.api_root}/service/status/${restId}/WEB`;
            fetch(url_stripe, {
              method: "GET",
              headers: {
                "Content-Type": "application/json"
              }
            })
              .then(response => response.json())
              .then(stripe => {
                this.setState(
                  {
                    stripe_info: stripe.object,
                    static_resource_endpoint:
                      stripe.object.STATIC_RESOURCE_ENDPOINT,
                    static_resource_sufix: stripe.object.STATIC_RESOURCE_SUFFIX
                  },
                  () => {
                    const donation_info_url =
                      `${config.api_root}/service/status/custom_checkout?type=donation`;
                    fetch(donation_info_url, {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json"
                      }
                    })
                      .then(response => response.json())
                      .then(responseData => {
                        console.log("single res results", responseData);
                        this.setState(
                          {
                            donation_payement_info: responseData.object
                          },
                          () => {
                            console.log(
                              "donation_payement_info",
                              this.state.donation_payement_info
                            );
                            if (
                              this.state.donation_payement_info
                                .HEARTLAND_PUBLISHABLE_KEY
                            ) {
                              this.setState({
                                heartland_publish_key: this.state
                                  .donation_payement_info
                                  .HEARTLAND_PUBLISHABLE_KEY
                              });
                            }
                            //this.sendData(this.state.donation_payement_info);
                          }
                        );
                      })
                      .catch(error =>
                        this.setState({
                          message: "Something bad happened " + error
                        })
                      );
                    console.log(
                      "saini*************************************************************************************************************************"
                    );
                    const restaurant_info_url = `${this.state.static_resource_endpoint}${restId}${this.state.static_resource_sufix}`;
                    console.log("restaurant-info", restaurant_info_url);
                    fetch(restaurant_info_url, {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json"
                      }
                    })
                      .then(response => response.json())
                      .then(responseData => {
                        console.log("single res results", responseData);
                        this.setState({
                          restaurant_info: responseData.object
                        });
                      })
                      .catch(error =>
                        this.setState({
                          message: "Something bad happened " + error
                        })
                      );
                  }
                );
              })
              .catch(error =>
                this.setState({
                  message: "Something bad happened " + error
                })
              );
          }
        );
      })
      .catch(error =>
        this.setState({
          message: "Something bad happened " + error
        })
      );
  }

  handleFieldChange = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    this.setState(new_state);
  };

  handleFirstNameChange = (field, event) => {
    const first_name = event.target.value;
    if (first_name.match(/^[a-zA-Z ]*$/)) {
      this.setState({ firstname_error: false });
    } else {
      this.setState({ firstname_error: true });
    }
  };

  handleLastNameChange = (field, event) => {
    const last_name = event.target.value;
    if (last_name.match(/^[a-zA-Z ]*$/)) {
      this.setState({ lastname_error: false });
    } else {
      this.setState({ lastname_error: true });
    }
  };

  handlePhoneChange = (field, event) => {
    const phone = event.target.value;
    if (phone.length == 10) {
      this.setState({ phone_error: false });
    } else {
      this.setState({ phone_error: true });
    }
  };

  handleEmailChange = (field, event) => {
    const email = event.target.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      this.setState({ email_error: false });
    } else {
      this.setState({ email_error: true });
    }
  };

  handlePostalCodeChange = (field, event) => {
    const postal_code = event.target.value;
    if (postal_code.length < 5 || postal_code.length > 10) {
      this.setState({ postal_code_error: true });
    } else {
      this.setState({ postal_code_error: false });
    }
  };

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    if (
      this.state.donation_payement_info &&
      this.state.donation_payement_info.PAYMENT_GATEWAY
    ) {
      if (this.state.donation_payement_info.PAYMENT_GATEWAY == "hps") {
        console.log("nehawithoutstripe");
        this.setState({
          order_now_click: true
        });
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();
        this.payment = new window.Heartland.HPS({
          publicKey: this.state.heartland_publish_key,
          cardNumber: this.state.cardNumber,
          cardCvv: this.state.cardCvv,
          cardExpMonth: this.state.cardExpMonth,
          cardExpYear: this.state.cardExpYear,

          success: resp => {
            this.success(resp.token_value);
          },

          error: resp => {
            this.error(resp.error.message);
          }
        });

        try {
          this.payment.tokenize();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  success = tokenvalue => {
    console.log("tokenvalue", tokenvalue);
    this.setState({ payment_complete: true, payment_token: tokenvalue });
  };

  error = error_msg => {
    console.log("error_msg", error_msg);
    this.setState({
      stripe_error: error_msg,
      order_now_click: false
    });
  };

  onToken = (token, addresses) => {
    this.setState({
      order_now_click: true
    });
    this.setState({ payment_complete: true, payment_token: token.id });
    console.log("payment_complete payment_token", this.state.payment_token);
  };

  cartemptyhandler = () => {
    this.setState({
      showmodal_cart_empty: true
    });
  };
  showAgreementAlert = () => {
    this.setState({
      donationAgree: true
    });
  };
  closeAgreementAlert = () => {
    this.setState({
      donationAgree: false
    });
  };
  showRequiredFieldsError = () => {
    this.setState({
      requiredfielderror: true
    });
  };
  closeRequiredfielderror = () => {
    this.setState({
      requiredfielderror: false
    });
  };
  handleclosecartempty = () => {
    this.setState({
      showmodal_cart_empty: false,
      donationAgree: false
    });
  };

  // donationAmountHandler = () =>{
  //   if(this.state.radio_amount == "other"){
  //     if(this.state.custom_amount != null){
  //       this.setState({
  //         amount:this.state.custom_amount
  //       })
  //     }
  //     else{
  //       this.setState({
  //         amount : 0
  //       })
  //     }
  //   }
  //   else{
  //     this.setState({
  //       amount:this.state.radio_amount
  //     })
  //   }
  // }

  donation() {
    //const restId = "ad9b84a54cc879626ab3cfd602e363b6";
    const restId = this.props.match.params.id;
    this.setState({
      order_loader: true,
      cart_empty_click: false
    });
    const url4 = `${config.api_base}/users/business/trust/payment/checkout?access_token=${this.state.final_user_token}`;
    fetch(url4, {
      method: "POST",
      body: JSON.stringify({
        fields: {
          amount:
            this.state.radio_amount == "other"
              ? this.state.custom_amount
              : this.state.radio_amount,
          trustFor: restId,
          firstName: this.state.first_name,
          middleName: "",
          lastName: this.state.last_name,
          address1: this.state.address,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          postalCode: this.state.postal_code,
          mobileNumber: this.state.telephone,
          email: this.state.email,
          notes: this.state.notes_restaurant,
          cardToken: this.state.payment_token
        },
        form_id: "",
        user_id: this.state.final_user_email
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(donation => {
        this.setState({
          donation_info: donation,
          order_loader: false,
          cart_empty_click: true
        });
        if (donation.object && donation.object.error) {
          this.setState({
            checkout_error: donation.object.error,
            order_now_click: false
          });
        }
      })
      .catch(error =>
        this.setState({
          message: "Something bad happened " + error
        })
      );
  }

  donationTermToggle = event => {
    this.setState({
      donationTermState: !this.state.donationTermState
    });
  };
  render() {
    console.log("donationTermState", this.state.donationTermState);
    console.log("restaurant_info", this.state.restaurant_info.name);
    console.log("radio amount", this.state.radio_amount);
    console.log("custom amount", this.state.custom_amount);
    console.log("amount", this.setState.amount);
    //const restId = "ad9b84a54cc879626ab3cfd602e363b6";
    // if(this.state.radio_amount != null){
    //   this.donationAmountHandler()
    // }
    const restId = this.props.match.params.id;
    if (this.props.match.params.id == null) {
      return <Redirect to="/" />;
    }
    if (this.state.payment_complete == true) {
      this.donation();
      this.setState({ payment_complete: false });
    }
    if (
      Object.keys(this.state.donation_info).length > 0 &&
      this.state.donation_info.request_status == true
    ) {
      return <Redirect to="/thank-you" />;
    }
    return (
      <>
        <HeaderTwo />
        <HeaderSlider donation="donation" />
        <div className="donation">
          <div className="container">
            <div className="row donation-main">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="donation-form">
                  <h2 className="inner text-center">
                    Support Your Restaurant or Food Bank To Get Through the COVID-19 Hardship
                  </h2>
                  <h3 className="inner text-center">
                    {" "}
                    {this.state.restaurant_info &&
                    Object.keys(this.state.restaurant_info).length > 0
                      ? `Donating To - ${this.state.restaurant_info.name}`
                      : null}
                  </h3>
                    <fieldset>
                      <Form.Group as={Col} className="DonationAmount">
                        <Form.Label>Donation amount *</Form.Label>
                        <Row>
                          <Col sm={2}>
                            <Form.Check
                              type="radio"
                              label="$5"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios1"
                              value="5"
                              onClick={e =>
                                this.setState({ radio_amount: e.target.value })
                              }
                              required
                            />
                          </Col>
                          <Col sm={2}>
                            <Form.Check
                              type="radio"
                              label="$10"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios2"
                              value="10"
                              onClick={e =>
                                this.setState({ radio_amount: e.target.value })
                              }
                              required
                            />
                          </Col>
                          <Col sm={2}>
                            <Form.Check
                              type="radio"
                              label="$20"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios3"
                              value="20"
                              onClick={e =>
                                this.setState({ radio_amount: e.target.value })
                              }
                              required
                            />
                          </Col>
                          <Col sm={2}>
                            <Form.Check
                              type="radio"
                              label="$50"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios4"
                              value="50"
                              onClick={e =>
                                this.setState({ radio_amount: e.target.value })
                              }
                              required
                            />
                          </Col>
                          <Col sm={2}>
                            <Form.Check
                              type="radio"
                              label="$100"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios5"
                              value="100"
                              onClick={e =>
                                this.setState({ radio_amount: e.target.value })
                              }
                              required
                            />
                          </Col>
                          <Col sm={2}>
                            <Form.Check
                              type="radio"
                              label="other"
                              name="formHorizontalRadios"
                              id="formHorizontalRadios6"
                              value="other"
                              onClick={e =>
                                this.setState({ radio_amount: e.target.value })
                              }
                              required
                            />
                          </Col>
                        </Row>
                        {this.state.radio_amount == "other" ? (
                          <Form.Group controlId="formBasicaddress">
                            <Form.Label>Custom Amount*</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="eg:150"
                              value={this.state.custom_amount}
                              onChange={e =>
                                this.setState({ custom_amount: e.target.value })
                              }
                              required
                            />
                          </Form.Group>
                        ) : null}
                      </Form.Group>
                    </fieldset>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formBasicfname">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          value={this.state.first_name}
                          onChange={e =>
                            this.handleFieldChange("first_name", e)
                          }
                          onBlur={e =>
                            this.handleFirstNameChange("first_name", e)
                          }
                          required
                        />
                        {this.state.firstname_error ? (
                          <span className="phone-error">
                            *Please enter alphabet characters only.
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col} controlId="formBasiclname">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          value={this.state.last_name}
                          onChange={e => this.handleFieldChange("last_name", e)}
                          onBlur={e =>
                            this.handleLastNameChange("last_name", e)
                          }
                          required
                        />
                        {this.state.lastname_error ? (
                          <span className="phone-error">
                            *Please enter alphabet characters only.
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formBasicTelephone">
                        <Form.Label>Telephone/mobile *</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Telephone/mobile"
                          min="5"
                          value={this.state.telephone}
                          onChange={e => this.handleFieldChange("telephone", e)}
                          onBlur={e => this.handlePhoneChange("telephone", e)}
                          required
                        />
                        {this.state.phone_error ? (
                          <span className="phone-error">
                            Phone Number must be 10 digits
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col} controlId="formBasicEmail">
                        <Form.Label>Email address *</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={this.state.email}
                          onChange={e => this.handleFieldChange("email", e)}
                          onBlur={e => this.handleEmailChange("email", e)}
                          required
                        />
                        {this.state.email_error ? (
                          <span className="phone-error">
                            Please enter valid email
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="formBasicaddress">
                      <Form.Label>Address1 *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Your full address"
                        value={this.state.address}
                        onChange={e => this.handleFieldChange("address", e)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicaddress">
                      <Form.Label>Address2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Your full address"
                        value={this.state.address2}
                        onChange={e => this.handleFieldChange("address2", e)}
                      />
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formBasicCity">
                        <Form.Label>City *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          value={this.state.city}
                          onChange={e => this.handleFieldChange("city", e)}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formBasicPostalcode">
                        <Form.Label>Postal code *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Postal code"
                          value={this.state.postal_code}
                          onChange={e =>
                            this.handleFieldChange("postal_code", e)
                          }
                          onBlur={e =>
                            this.handlePostalCodeChange("postal_code", e)
                          }
                          required
                        />
                        {this.state.postal_code_error ? (
                          <span className="phone-error">
                            Postal code must be in between 5 digits to 10 digits
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="State">
                        <Form.Label>State *</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => this.handleFieldChange("state", e)}
                          required
                        >
                          {this.state.state_info &&
                          this.state.state_info.length > 0
                            ? this.state.state_info.map((statedata, index) => (
                                <option value={statedata.id} key={index}>
                                  {statedata.name}
                                </option>
                              ))
                            : null}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} controlId="Country">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => this.handleFieldChange("country", e)}
                          required
                        >
                          {
                            // <option value ="">Select County</option>
                          }
                          <option value={this.state.country_info.id}>
                            {this.state.country_info.name}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Notes for the restaurant</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        value={this.state.notes_restaurant}
                        onChange={e =>
                          this.handleFieldChange("notes_restaurant", e)
                        }
                      />
                    </Form.Group>
                  {this.state.radio_amount != "" ? (
                    (this.state.radio_amount == "other" &&
                      this.state.custom_amount != "") ||
                    this.state.radio_amount == "5" ||
                    this.state.radio_amount == "10" ||
                    this.state.radio_amount == "20" ||
                    this.state.radio_amount == "50" ||
                    this.state.radio_amount == "100" ? (
                      this.state.first_name != "" &&
                      this.state.last_name != "" &&
                      this.state.telephone != "" &&
                      this.state.email != "" &&
                      this.state.telephone.length === 10 &&
                      this.state.email_error === false &&
                      this.state.firstname_error === false &&
                      this.state.lastname_error === false &&
                      this.state.address != "" &&
                      this.state.city != "" &&
                      this.state.postal_code != "" &&
                      this.state.postal_code.length >= 5 &&
                      this.state.postal_code.length <= 10 &&
                      this.state.state != "" &&
                      this.state.country != "" ? (
                        <>
                          <div>
                            {this.state.stripe_error != null ? (
                              <span className="stripe-error">
                                {this.state.stripe_error}
                              </span>
                            ) : null}
                            {this.state.checkout_error != null ? (
                              <span className="stripe-error">
                                {this.state.checkout_error}
                              </span>
                            ) : null}
                            {this.state.donation_payement_info &&
                            this.state.donation_payement_info
                              .PAYMENT_GATEWAY ? (
                              this.state.donation_payement_info
                                .PAYMENT_GATEWAY == "hps" ? (
                                <>
                                  <Form id="standard" action="" method="GET">
                                    <Form.Group controlId="formBasicaddress">
                                      <Form.Label>Card Number:</Form.Label>
                                      <Form.Control
                                        type="tel"
                                        id="standardCardNumber"
                                        placeholder="••••••••••••••••"
                                        value={this.state.cardNumber}
                                        onChange={e =>
                                          this.setState({
                                            cardNumber: e.target.value
                                          })
                                        }
                                        maxlength="16"
                                        required
                                      />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicaddress">
                                      <Form.Label>Card Expiration:</Form.Label>
                                    </Form.Group>
                                    <Form.Row>
                                      <Form.Group
                                        as={Col}
                                        controlId="formBasicfname"
                                      >
                                        <Form.Label>
                                          Expiration Month *
                                        </Form.Label>
                                        <Form.Control
                                          type="tel"
                                          placeholder="MM"
                                          value={this.state.cardExpMonth}
                                          onChange={e =>
                                            this.setState({
                                              cardExpMonth: e.target.value
                                            })
                                          }
                                          maxlength="2"
                                          required
                                        />
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        controlId="formBasiclname"
                                      >
                                        <Form.Label>
                                          Expiration Year *
                                        </Form.Label>
                                        <Form.Control
                                          type="tel"
                                          placeholder="YYYY"
                                          value={this.state.cardExpYear}
                                          onChange={e =>
                                            this.setState({
                                              cardExpYear: e.target.value
                                            })
                                          }
                                          maxlength="4"
                                          required
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Group controlId="formBasicaddress">
                                      <Form.Label>Card CVV:</Form.Label>
                                      <Form.Control
                                        type="tel"
                                        id="standardCardCvv"
                                        placeholder="CVV"
                                        value={this.state.cardCvv}
                                        onChange={e =>
                                          this.setState({
                                            cardCvv: e.target.value
                                          })
                                        }
                                        required
                                      />
                                    </Form.Group>
                                  </Form>
                                  <Form.Group controlId="formBasicCheckboxDonationTerm">
                                    <Form.Check
                                      type="checkbox"
                                      label={
                                        <a
                                          href="/donationterms"
                                          target="_blank"
                                        >
                                          Agree to Donation Terms
                                        </a>
                                      }
                                      className="DonationTermLabel"
                                      onClick={() =>
                                        this.setState({
                                          donationTermState: !this.state
                                            .donationTermState
                                        })
                                      }
                                      required
                                    />
                                  </Form.Group>
                                  <button
                                    disabled={
                                      this.state.order_now_click ||
                                      this.state.donationTermState === false
                                        ? true
                                        : false
                                    }
                                    className="Loc-form-btn"
                                    onClick={
                                      this.state.donationTermState
                                        ? this.handleSubmit
                                        : this.showAgreementAlert
                                    }
                                  >
                                    <span>DONATE NOW</span>
                                  </button>
                                </>
                              ) : this.state.donation_payement_info
                                  .PAYMENT_GATEWAY == "stripe" ? (
                                <>
                                  <Form.Group controlId="formBasicCheckboxDonationTerm">
                                    <Form.Check
                                      type="checkbox"
                                      label={
                                        <a
                                          href="/donationterms"
                                          target="_blank"
                                        >
                                          Agree to Donation Terms
                                        </a>
                                      }
                                      className="DonationTermLabel"
                                      onClick={() =>
                                        this.setState({
                                          donationTermState: !this.state
                                            .donationTermState
                                        })
                                      }
                                      required
                                    />
                                  </Form.Group>
                                  {this.state.order_now_click ||
                                  this.state.donationTermState === false ? (
                                    <button
                                      // type="submit"
                                      form="AddressForm"
                                      className="StripeCheckout Loc-form-btn"
                                      //value="submit"
                                      onClick={this.showAgreementAlert}
                                    >
                                      {" "}
                                      <span>DONATE NOW</span>
                                    </button>
                                  ) : (
                                    <StripeCheckout
                                      amount={
                                        this.state.radio_amount == "other"
                                          ? this.state.custom_amount * 100
                                          : this.state.radio_amount * 100
                                      }
                                      //billingAddress
                                      description="Restaurant on Demand"
                                      locale="auto"
                                      name="Dining Tek"
                                      email="support@diningtek.com"
                                      stripeKey={
                                        this.state.donation_payement_info
                                          ? this.state.donation_payement_info
                                              .STRIPE_PUBLISHABLE_KEY
                                          : "pk_test_sn4v71GtpdSuGyF3oVJLSj7I"
                                      }
                                      token={this.onToken}
                                      label="DONATE NOW"
                                      className="Loc-form-btn"
                                      allowRememberMe={false}
                                    />
                                  )}
                                </>
                              ) : null
                            ) : (
                              <>
                                <Form.Group controlId="formBasicCheckboxDonationTerm">
                                  <Form.Check
                                    type="checkbox"
                                    label={
                                      <a href="/donationterms" target="_blank">
                                        Agree to Donation Terms
                                      </a>
                                    }
                                    className="DonationTermLabel"
                                    onClick={() =>
                                      this.setState({
                                        donationTermState: !this.state
                                          .donationTermState
                                      })
                                    }
                                    required
                                  />
                                </Form.Group>
                                {this.state.order_now_click ||
                                this.state.donationTermState === false ? (
                                  <button
                                    form="AddressForm"
                                    className="StripeCheckout Loc-form-btn"
                                    onClick={this.showAgreementAlert}
                                  >
                                    {" "}
                                    <span>DONATE NOW</span>
                                  </button>
                                ) : (
                                  <StripeCheckout
                                    amount={
                                      this.state.radio_amount == "other"
                                        ? this.state.custom_amount * 100
                                        : this.state.radio_amount * 100
                                    }
                                    //billingAddress
                                    description="Restaurant on Demand"
                                    locale="auto"
                                    name="Dining Tek"
                                    email="support@diningtek.com"
                                    stripeKey={
                                      this.state.donation_payement_info
                                        ? this.state.donation_payement_info
                                            .STRIPE_PUBLISHABLE_KEY
                                        : "pk_test_sn4v71GtpdSuGyF3oVJLSj7I"
                                    }
                                    token={this.onToken}
                                    label="ORDER NOW"
                                    className="Loc-form-btn"
                                    allowRememberMe={false}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <button
                          form="AddressForm"
                          className="StripeCheckout Loc-form-btn"
                          onClick={this.showRequiredFieldsError}
                          disabled={
                            this.state.phone_error ||
                            this.state.postal_code_error ||
                            this.state.email_error ||
                            this.state.firstname_error ||
                            this.state.lastname_error
                              ? true
                              : false
                          }
                        >
                          {" "}
                          <span>DONATE NOW</span>
                        </button>
                      )
                    ) : (
                      <button
                        disabled={!this.state.cart_empty_click}
                        className="StripeCheckout Loc-form-btn"
                        onClick={this.cartemptyhandler}
                      >
                        {" "}
                        <span>DONATE NOW</span>
                      </button>
                    )
                  ) : (
                    <button
                      disabled={!this.state.cart_empty_click}
                      className="StripeCheckout Loc-form-btn"
                      onClick={this.cartemptyhandler}
                    >
                      {" "}
                      <span>DONATE NOW</span>
                    </button>
                  )}
                  <div className="back-to-menu text-center">
                    <Link
                      to={{
                        pathname: `/restaurant/${restId}`
                      }}
                    >
                      Back To Menu
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>

        <Footer />

        <Modal show={this.state.showmodal_cart_empty} id="modal3" size="sm">
          <Modal.Body>Amount should be greater than 0.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleclosecartempty}>
              ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.donationAgree} id="modal3" size="sm">
          <Modal.Body>Please Check Agree to Donation Terms</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeAgreementAlert}>
              ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.requiredfielderror} id="modal3" size="sm">
          <Modal.Body>Please Completed All Required Fields</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeRequiredfielderror}>
              ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default Donation;
