import React, { Component } from "react";
import Slider from "react-slick";
const settings = {
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 2,
  adaptiveHeight:true
};

class Service extends Component {
  render() {
    return (
      <section id="looking-something">
        <div className="container">
          <h2>Looking for something else?</h2>
          <div className="smething-slider">
            <div className="something-else slider">
              <Slider {...settings}>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking1.png" />
                    <h4>Indian</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking2.png" />
                    <h4>Mexican</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking3.png" />
                    <h4>Asian</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking4.png" />
                    <h4>Italian</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking5.png" />
                    <h4>French</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking1.png" />
                    <h4>American</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking6.png" />
                    <h4>Mediterian</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking7.png" />
                    <h4>Asian</h4>
                  </div>
                </div>
                <div>
                  <div className="slide-area">
                    <img src="/images/looking4.png" />
                    <h4>Franch</h4>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Service;
