import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Button ,
    Container,
      Row,
      Col,
    Form,
    } from 'react-bootstrap';
import {config} from '../config';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";


class ChangePassword extends Component{
  constructor(props){
    super(props);
    this.state = {
      otp : null,
      password : null,
      otp_info : [],
      otp_Process :false
    }
    this.ChangePasswordInformation = this.ChangePasswordInformation.bind(this);
  }
  OnHandleFieldChange = (field, event) => {
      const new_state = {};
      new_state[field] = event.target.value;
      this.setState(new_state);
  };

  ChangePasswordInformation(event){
    event.preventDefault();
      const logindata = {
        "otp" : this.state.otp,
        "password" : this.state.password
      }
      console.log("data first name",logindata.first_name);
      const forget_token = localStorage.getItem('forget_token');
      const forget_user_email = localStorage.getItem('forget_user_email');
      const url2 =
        `${config.api_root}/security/session/users/password/rescue/verify_app`;
      fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          fields : {
            otp: logindata.otp,
            password: logindata.password,
            token:forget_token
          },
          form_id: "",
          user_id: forget_user_email

        }),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => response.json())
            .then(verify => {
              this.setState({
                otp_info: verify.object
              })
              if(verify.request_status) {
                  this.setState({
                    otp_Process : true
                  })
                  localStorage.removeItem('forget_token');
                  localStorage.removeItem('forget_user_email');
              }
            })
        .catch(error =>
          this.setState({
            message: "Something bad happened " + error
          })
        );
  }
  render(){
    // if(this.state.forget_Process === true){
    //     return <Redirect to="/" />;
    // }

    return(
      <>
        <Header />
        <div className = "container">
          <div className = "row ">
              <div className = "col-md-2"></div>
              <div className = "col-md-8 ">
              {this.state.otp_Process === false ? (
              <Form className="signup-content" onSubmit = {this.ChangePasswordInformation}>
                  <div className = "container">
                  <h1>Change Password</h1>
                  {this.state.otp_info.error ? (<p>{this.state.otp_info.error}</p>) : null}
                  <Form.Group controlId="formBasicOtp">
                      <Form.Label>OTP</Form.Label>
                      <Form.Control type="text"  value = {this.state.otp} onChange = {e => this.OnHandleFieldChange('otp', e)} required/>
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type="password"  value = {this.state.password} onChange = {e => this.OnHandleFieldChange('password', e)} required/>
                  </Form.Group>
                  <Button type="submit" className = "register-form-button">
                  Reset Password
                  </Button>
                  </div>
              </Form>
              ) : (<div className ="thanku_registration">
                  <h4>{this.state.otp_info.message}</h4>
              </div>)}
                </div>
              <div className = "col-md-2"></div>
          </div>
        </div>

        <Footer />
        </>
    )
  }
}

export default ChangePassword;
