import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Button ,
    Container,
      Row,
      Col,
    Form,
    } from 'react-bootstrap';
import {config} from '../config';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

const recaptchaRef = React.createRef();

class Signin extends Component{
  constructor(props){
    super(props);
    this.state = {
      firstname : null,
      lastname : null,
      email : null,
      city : null,
      password : null,
      error_message:null,
      recaptcha_value:null,
      login_info : [],
      otp_info:[],
      login_Process :false
    }
    this.Logininformation = this.Logininformation.bind(this);
  }
  OnHandleFieldChange = (field, event) => {
      const new_state = {};
      new_state[field] = event.target.value;
      this.setState(new_state);
  };

  Logininformation(event){
    event.preventDefault();
    if(this.state.recaptcha_value != null){
      const logindata = {
        "email" : this.state.email,
        "password" :this.state.password
      }
      console.log("data first name",logindata.first_name);
      const url2 =
        `${config.api_root}/security/session/users/login`;
      fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          user: logindata.email,                      //hjjcjcjcj@gmail.com
          password: logindata.password,                                 //1111
          bucket_id:""
        }),
        headers: {
          "Content-Type": "application/json",
          "device_id" : "21212121121212wqwqw",                //device id should be unique every time
          "key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
          "secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
        }
      }).then(response => response.json())
            .then(login => {
              this.setState({
                login_info: login.object
              })
              if(login.request_status) {
                  this.setState({
                    login_Process : true
                  })
                console.log('correct login');
                localStorage.setItem('access_token', login.object.access_token);
                localStorage.setItem('user', this.state.email);

                if (localStorage.getItem('user') != null && localStorage.getItem('access_token') != null){
                  window.location.reload();
                }

                // .then(() =>{
                //   console.log('Yes this is sending data');
                //   localStorage.setItem('access_token', this.state.login_info.access_token);
                //   localStorage.setItem('user', this.state.email);
                //   this.sendData(this.state.email,this.state.login_info.access_token);
                // })
              }
              else {
                this.setState({
                  error_message: "Invalid username or password"
                })
              }
            })
        .catch(error =>
          this.setState({
            message: "Something bad happened " + error
          })
        );
    }
  }

    RecaptchaHandler = () =>{
      const recaptchaValue = recaptchaRef.current.getValue();
      this.setState({
        recaptcha_value : recaptchaValue
      })
      setInterval(() => {
        this.setState({
          recaptcha_value: null
        })
      }, 120000);
      console.log("recaptchaValue",recaptchaValue);
    }
  render(){
    if(this.state.login_Process === true){
        return <Redirect to="/" />;
    }

    return(
      <>
        <Header />
        <div className = "container">
          <div className = "row ">
              <div className = "col-md-2"></div>
              <div className = "col-md-8 ">
              <Form className="signup-content" onSubmit = {this.Logininformation}>
                  <div className = "container">
                  <h1>Sign In</h1>
                  {this.state.login_info.error ? (<p>{this.state.login_info.error}</p>) : null}
                  <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" value = {this.state.email} onChange = {e => this.OnHandleFieldChange('email', e)} required/>
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" value = {this.state.password} onChange = {e => this.OnHandleFieldChange('password', e)} required/>
                  </Form.Group>
                  <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={config.site_key}
                        onChange={this.RecaptchaHandler}
                    />
                  <Form.Group controlId="formBasicChecbox">
                      <Link to = "/forgetpassword"><Form.Text   >Forgot password</Form.Text></Link>
                  </Form.Group>
                  <Button type="submit" className = "register-form-button">
                  Login
                  </Button>
                  </div>
              </Form>
                </div>
              <div className = "col-md-2"></div>
          </div>
        </div>

        <Footer />
        </>
    )
  }
}

export default Signin;
