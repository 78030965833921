import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboveHeader from "./TermAndCondition";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import App from "../App";
import { config } from "../config";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
const recaptchaRef = React.createRef();
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleShowlogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    // localStorage.removeItem('access_token').then(() => {
    //   return <Redirect to="/" />
    // })
    if (
      localStorage.getItem("user") === null &&
      localStorage.getItem("access_token") === null
    ) {
      window.location.reload();
    }
  };

  render() {
    let local_user = localStorage.getItem("user");
    let local_token = localStorage.getItem("access_token");
    const header_content =
      local_user === null && local_token === null ? (
        <div className="login-btns">
          <a href="/signin" className="login-btn">
            <i className="far fa-user" /> Login
          </a>
          <a href="/signup" className="signup-btn">
            Signup <i className="fas fa-long-arrow-alt-right" />
          </a>
        </div>
      ) : (
        <div className="login-btns">
          <a className="signup-btn" onClick={this.handleShowlogout}>
            Logout <i className="fas fa-long-arrow-alt-right" />
          </a>
        </div>
      );

    return (
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-5 col-sm-4">
                <div id="logo">
                  <a href="/" href>
                    <img src="/images/logo.jpg" />
                  </a>
                </div>
              </div>
              <div className="col-7 col-sm-8">{header_content}</div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
